import { useJudgeSize } from '@common/utils/JudgeSize';
import { useNavigate, useParams } from 'react-router-dom';

import orderProcess1Img from '@common/assets/orderProcess-1.png';
import orderProcess2Img from '@common/assets/orderProcess-2.png';
import orderProcess3Img from '@common/assets/orderProcess-3.png';
import orderProcess4Img from '@common/assets/orderProcess-4.png';

export const OrderProcess = () => {
  const navigate = useNavigate();
  const isSp = useJudgeSize();
  const { orderId } = useParams();
  if (!orderId) throw new Error('Routing Error.');
  return (
    <div className={`flex flex-col gap-1 mt-8 ${isSp ? 'mx-4' : 'mx-32'}`}>
      <p className="text-3xl font-bold mx-auto">商品のお受け取り方法</p>
      <div className="mt-2">
        <p className="text-lg font-bold">1.メールの通知を確認</p>
        <p className="mt-1">
          空中配送ロボットがご指定の受取BOXに荷物を入れ終えたら、ご登録のメールアドレスにご連絡が入ります。
        </p>
        <img
          src={orderProcess1Img}
          alt=""
          className="responsive-img"
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            objectFit: 'cover',
            marginTop: '16px',
            marginBottom: '16px',
          }}
        />
      </div>
      <div className="mt-2">
        <p className="text-lg font-bold">2.受取BOXでQRコードをかざす</p>
        <p className="mt-1">
          配送状況確認の画面からQRコードを表示し、受取BOXのカメラにかざして下さい。
        </p>
        <img
          src={orderProcess2Img}
          alt=""
          className="responsive-img"
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            objectFit: 'cover',
            marginTop: '16px',
            marginBottom: '16px',
          }}
        />
      </div>
      <div className="mt-2">
        <p className="text-lg font-bold">3.商品を取り出す</p>
        <p className="mt-1">
          ご注文の商品が入っている扉が開きます。内側のもうひとつの扉を開いて商品を取り出してください。
        </p>
        <img
          src={orderProcess3Img}
          alt=""
          className="responsive-img"
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            objectFit: 'cover',
            marginTop: '16px',
            marginBottom: '16px',
          }}
        />
        <img
          src={orderProcess4Img}
          alt=""
          className="responsive-img"
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            objectFit: 'cover',
            marginTop: '16px',
            marginBottom: '16px',
          }}
        />
      </div>
      {/* <div className="w-64 h-64 bg-slate-300 mx-auto">受け取り方法のイラスト(ダミー)</div> */}
      <div
        className="w-full mt-4 py-3 rounded-full cursor-pointer 
                  text-center text-xl font-bold border border-primary text-primary bg-primaryContrast"
        onClick={() => {
          navigate('/order/orders/' + orderId);
        }}
      >
        配送状況確認へ戻る
      </div>
    </div>
  );
};
