import { DialogOk } from '@common/components/Dialog';
import {
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
} from '@mui/material';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';

export const ResetPassword = () => {
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const { username } = useParams();
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);

  type FormType = {
    confirmCode: string;
    password: string;
  };
  const { handleSubmit, control } = useForm<FormType>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      confirmCode: '',
      password: '',
    },
  });

  if (!username) {
    return <div className="mt-8">URLが不正です</div>;
  }

  const onSubmit: SubmitHandler<FormType> = async (data) => {
    try {
      setProcessing(true);
      await Auth.forgotPasswordSubmit(username, data.confirmCode, data.password);
      setProcessing(false);
      setOpenSuccess(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: Error | any) {
      setProcessing(false);
      if (error.code) {
        console.error(error);
        if (error.code === 'CodeMismatchException') {
          setMessage('認証コードが正しくありません');
        } else {
          setMessage('想定外のエラーです');
        }
      }
      setOpen(true);
    }
  };

  return (
    <div>
      <Backdrop open={processing} sx={{ zIndex: 10000 }}>
        <CircularProgress />
      </Backdrop>
      <DialogOk
        open={open}
        callbackOk={() => setOpen(false)}
        title="エラー"
        message={message}
      ></DialogOk>
      <DialogOk
        open={openSuccess}
        callbackOk={() => navigate('/login')}
        title="パスワードのリセットが完了しました"
        message="ログインを行い、お買い物をお楽しみください"
      ></DialogOk>

      <div className="w-full mt-4 flex justify-center items-center">
        <Paper className="w-[22rem] p-8" elevation={6}>
          <p className="font-semibold text-xl">パスワードリセット</p>
          <p className="mt-2 text-sm">メールに記載の認証コードと</p>
          <p className="text-sm">新しいパスワードを入力してください</p>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col items-center gap-4 mx-auto mt-1"
          >
            <Controller
              name="confirmCode"
              control={control}
              rules={{ required: { value: true, message: '入力必須項目です' } }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="認証コード"
                  variant="standard"
                  size="medium"
                  className="w-full"
                  error={Boolean(error?.message)}
                  helperText={error?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              rules={{ required: { value: true, message: '入力必須項目です' } }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="パスワード"
                  type={isVisiblePassword ? 'text' : 'password'}
                  variant="standard"
                  size="medium"
                  className="w-full"
                  error={Boolean(error?.message)}
                  helperText={error?.message}
                  {...field}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setIsVisiblePassword(!isVisiblePassword)}>
                          {isVisiblePassword ? (
                            <VisibilityOffRoundedIcon />
                          ) : (
                            <VisibilityRoundedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <div className="mt-2">
              <Button variant="contained" type="submit">
                更新
              </Button>
            </div>
          </form>
        </Paper>
      </div>
    </div>
  );
};
