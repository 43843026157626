import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { RecoilKeys } from './RecoilKeys';

const WantedRoute = atom<string>({
  key: RecoilKeys.WANTED_ROUTE,
  default: '/top',
});

export const DataWantedRoute = {
  useSetWantedRouteState: () => useSetRecoilState(WantedRoute),
  useWantedRouteValue: () => useRecoilValue(WantedRoute),
};
