import { dataOrder } from '@common/data/Order';
import { useJudgeSize } from '@common/utils/JudgeSize';
import { QRCodeSVG } from 'qrcode.react';
import { useNavigate, useParams } from 'react-router-dom';

export const OrderQr = () => {
  const navigate = useNavigate();
  const isSp = useJudgeSize();
  const { orderId } = useParams();
  if (!orderId) throw new Error('Routing Error.');
  const order = dataOrder.history.useOrderItem(orderId);

  return (
    <div className={`flex flex-col items-center gap-1 mt-8 ${isSp ? 'mx-2' : 'mx-32'}`}>
      <p className="text-3xl font-bold ">商品受け取り用QRコード</p>
      <p className="mt-2">QRコードを受け取りBOXのモニターにかざして商品をお受け取りください。</p>
      <div className="w-64">
        <QRCodeSVG
          value={orderId}
          style={{ width: '95%', height: 'auto' }}
          size={128}
          bgColor={'#ffffff'}
          fgColor={'#000000'}
          level={'L'}
          includeMargin={true}
          imageSettings={{
            height: 480,
            width: 480,
            excavate: false,
            src: '',
            x: 0,
            y: 0,
          }}
        />
      </div>
      <p className="text-xl font-bold">受け取り番号：{order?.receiptNumber}</p>
      <div
        className="w-full mt-4 py-3 rounded-full cursor-pointer 
                  text-center text-xl font-bold border border-primary text-primary bg-primaryContrast"
        onClick={() => {
          navigate('/order/orders/' + orderId);
        }}
      >
        配送状況確認へ戻る
      </div>
    </div>
  );
};
