import { dataOrder } from '@common/data/Order';
import { Backdrop, Box, CircularProgress, Paper, Skeleton } from '@mui/material';
import { OrderStepper } from '../order/OrderStepper';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { useNavigate } from 'react-router-dom';
import { dataShopItemStock } from '@common/data/ShopItemStock';
import { dataShop } from '@common/data/Shop';
import { useState } from 'react';
import { CONST } from '@common/global';
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  InfoWindowF,
  // OverlayView,
  // OverlayViewF,
} from '@react-google-maps/api';
import { dataDeliveryTo } from '@common/data/OrderDeliveryTo';
import { dataDeliveryTime } from '@common/data/OrderDeliveryTime';
import img_spot from '@common/assets/spot_128x128.png';
import img_spot_disabled from '@common/assets/spot_gray_128x128.png';
import img_select_spot from '@common/assets/spot_select_128x128.png';
import { ApiSpot } from '@common/data/Api';

export function SelectBox({ isOrdering }: { isOrdering: boolean }) {
  const deliveryTimeRefresh = dataOrder.deliveryTime.useDeliveryTimeOptionRefresher();
  const [, setSelectedSpotId] = dataOrder.deliveryTo.useSpotSelectedIdState();
  const refreshStock = dataShopItemStock.useShopItemStockRefresher();
  const navigate = useNavigate();
  const [isOpenBackDrop, setIsOpenBackDrop] = useState(false);
  const selectedSpot = dataDeliveryTo.useSpotSelected();
  const hasTwoOrMoreSpots = dataShop.useHasTwoOrMoreSpots();

  const nextStepCallback = async (spotId?: string) => {
    if (spotId) {
      setSelectedSpotId(spotId);
    }
    setIsOpenBackDrop(true);
    await deliveryTimeRefresh();
    setIsOpenBackDrop(false);
    refreshStock();
    if (isOrdering) {
      navigate('/order/select_time');
    } else {
      navigate('/');
    }
  };

  const title = hasTwoOrMoreSpots ? 'お届け先選択' : 'お届け先確認';
  const body = hasTwoOrMoreSpots
    ? '希望の受取BOXを選択してください'
    : '受取BOXの場所をご確認ください';

  return (
    <Box sx={{ width: '100%', p: 1, pb: 12 }}>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isOpenBackDrop}>
        <CircularProgress />
      </Backdrop>
      {isOrdering && <OrderStepper activeStep={1} />}
      <div className="mt-2">
        <div className="text-2xl mb-2">
          <PlaceOutlinedIcon className="mb-1" /> {title}
        </div>
        <div className="text-sm mb-2 ml-2">{body}</div>
        <BoxMap />
      </div>
      <div className="flex flex-col items-center">
        <div className="flex gap-2 items-center p-2 rounded-md h-28">
          {selectedSpot.image.length !== 0 ? (
            <img
              alt="配達場所画像"
              src={selectedSpot.image[0]}
              className="h-full object-contain rounded-t-md"
            />
          ) : (
            <div className="text-center">画像なし</div>
          )}

          <p>{selectedSpot.spotName}</p>
        </div>
      </div>

      <PurchasePanel nextStepCallback={nextStepCallback} />
    </Box>
  );
}

const BoxMap = () => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: CONST.GOOGLE_MAPS_APIKEY,
  });
  const [, setSelectedSpotId] = dataOrder.deliveryTo.useSpotSelectedIdState();
  const shop = dataShop.useShopSelected();
  const deliveryAvailabilitySpotsStatus = dataDeliveryTime.useDeliveryAvailabilitySpotsStatus();
  const selectedSpot = dataDeliveryTo.useSpotSelected();
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [selectedMarker, setSelectedMarker] = useState<ApiSpot | null>(null);
  const isSpotEnable = (spotId: string): boolean => {
    return deliveryAvailabilitySpotsStatus?.[spotId] ?? false;
  };
  const handleMarkerClick = (spot: ApiSpot) => {
    if (map) {
      const bounds = map.getBounds();
      const markerPosition = new window.google.maps.LatLng(spot.latitude, spot.longitude);
      if (!bounds?.contains(markerPosition)) {
        map.panTo(markerPosition);
      }
    }

    setSelectedMarker(spot);
    if (isSpotEnable(spot.spotId)) {
      setSelectedSpotId(spot.spotId);
    }
  };

  const handleMapLoad = (map: google.maps.Map) => {
    setMap(map);
    const bounds = new window.google.maps.LatLngBounds();
    shop.spots.forEach((spot) => {
      bounds.extend(new window.google.maps.LatLng(spot.latitude, spot.longitude));
    });
    map.fitBounds(bounds);
    // コントロールボタンを作成
    const centerControl = createCenterControl(map, bounds);
    // コントロールボタンをマップの左上に追加
    map.controls[google.maps.ControlPosition.TOP_RIGHT].push(centerControl);
  };

  return isLoaded ? (
    <div style={{ position: 'relative' }}>
      <GoogleMap
        mapContainerStyle={{
          height: '35vh',
          width: '100%',
        }}
        options={{
          gestureHandling: 'greedy',
          styles: CONST.GOOGLE_MAPS_STYLES,
          mapTypeControl: false,
          streetViewControl: false,
          keyboardShortcuts: false,
          fullscreenControl: false, //全画面表示コントロール
        }}
        onLoad={handleMapLoad}
      >
        {shop.spots.map((spot) => (
          <MarkerF
            // MEMO: keyにMath.random()を使用するのは、GoogleMapの再描画を行うため
            key={Math.random()}
            icon={{
              url: isSpotEnable(spot.spotId)
                ? spot.spotId === selectedSpot.spotId
                  ? img_select_spot
                  : img_spot
                : img_spot_disabled,
              scaledSize: new google.maps.Size(50, 50),
            }}
            position={{ lat: spot.latitude, lng: spot.longitude }}
            onClick={() => {
              if (isSpotEnable(spot.spotId)) {
                setSelectedSpotId(spot.spotId);
              }
              handleMarkerClick(spot);
            }}
          />
        ))}
        {selectedMarker && (
          <InfoWindowF
            position={{ lat: selectedMarker.latitude, lng: selectedMarker.longitude }}
            options={{ pixelOffset: new window.google.maps.Size(0, -40) }}
            onCloseClick={() => setSelectedMarker(null)}
          >
            <div>
              {isSpotEnable(selectedMarker.spotId)
                ? 'こちらに配達されます'
                : '選択不可 利用可能な配達時間がありません'}
            </div>
          </InfoWindowF>
        )}
      </GoogleMap>
      {/* Google規約等をクリックさせないためのダミーボタン */}
      <button
        style={{
          position: 'absolute',
          bottom: '0',
          right: '0',
          backgroundColor: 'transparent',
          border: 'none',
          padding: '10px',
          width: '130px',
          height: '10px',
        }}
      ></button>
      <button
        style={{
          position: 'absolute',
          bottom: '0',
          left: '0',
          backgroundColor: 'transparent',
          border: 'none',
          padding: '10px',
          width: '70px',
          height: '30px',
        }}
      ></button>
    </div>
  ) : (
    <Skeleton
      sx={{
        height: '35vh',
        width: '100%',
      }}
    />
  );
};

function PurchasePanel({ nextStepCallback }: { nextStepCallback: () => void }) {
  const isAnySpotAvailableForDeliveryToday =
    dataDeliveryTime.useIsAnySpotAvailableForDeliveryToday();

  const caption = isAnySpotAvailableForDeliveryToday ? (
    'この受取BOXで受け取る'
  ) : (
    <>
      受取可能なBOXがありません
      <br />
      注文再開までお待ちください
    </>
  );

  const classname = isAnySpotAvailableForDeliveryToday
    ? 'w-full py-3 rounded-full cursor-pointer text-center text-2xl font-bold text-primaryContrast bg-primary'
    : 'w-full py-3 rounded-full cursor-pointer text-center text-l font-bold text-white bg-gray-300';

  // pb:7 は MyBottomNavigation に隠れる高さ
  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        p: 1,
        pb: 8,
      }}
    >
      <div
        className={classname}
        onClick={() => {
          if (isAnySpotAvailableForDeliveryToday) {
            nextStepCallback();
          }
        }}
      >
        {caption}
      </div>
    </Paper>
  );
}

function createCenterControl(map: google.maps.Map, bounds: google.maps.LatLngBounds) {
  const controlButton = document.createElement('button');

  // Set CSS for the control.
  controlButton.style.backgroundColor = '#fff';
  controlButton.style.border = '2px solid #fff';
  controlButton.style.borderRadius = '3px';
  controlButton.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
  controlButton.style.color = 'rgb(25,25,25)';
  controlButton.style.cursor = 'pointer';
  controlButton.style.fontFamily = 'Roboto,Arial,sans-serif';
  controlButton.style.fontSize = '16px';
  controlButton.style.lineHeight = '38px';
  controlButton.style.margin = '8px 0 22px';
  controlButton.style.padding = '0 5px';
  controlButton.style.textAlign = 'center';

  controlButton.textContent = '全体表示';
  controlButton.title = '受取BOXすべて表示';
  controlButton.type = 'button';

  controlButton.addEventListener('click', () => {
    map.fitBounds(bounds);
  });
  return controlButton;
}
