import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { agreementActions } from '@common/data/Agreement';
import { userActions } from '@common/data/User';
import { Agreement } from './Agreement';
import { DataWantedRoute } from '@common/data/WantedRoute';

export const PrivacyPolicy = () => {
  const user = userActions.useGetUser();
  const saveUserFunc = userActions.useStoreUser();
  const privacyPolicy = agreementActions.useFindAgreement('privacyPolicy');

  const processing = useRef(false);
  const navigate = useNavigate();
  const wantedRoute = DataWantedRoute.useWantedRouteValue();

  const agreeFunction = async () => {
    if (processing.current) return;

    processing.current = true;
    await saveUserFunc({ ...user, privacyPolicy: privacyPolicy?.version ?? '' });
    // MEMO: React18のレンダリング制御により、下記のようにしないとnavigateが
    // 想定通りに動作しない
    setTimeout(() => {
      processing.current = false;
      navigate(wantedRoute);
    });
  };

  const fileName = 'PrivacyPolicy';

  return (
    <Agreement
      title="プライバシーポリシー"
      fileName={fileName}
      agreeFunction={agreeFunction}
    ></Agreement>
  );
};
