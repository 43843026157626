import { Link, Paper, Typography, Box } from '@mui/material';

export const Contact = () => {
  return (
    <Paper
      sx={{ p: 4, mt: 2, mx: 'auto', width: '100%', maxWidth: '500px', boxShadow: 3 }}
      className="bg-gray-50"
    >
      <Typography variant="h5" sx={{ pb: 2, fontWeight: 'bold', color: 'blue.700' }}>
        お問い合わせ窓口
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: 'flex-start',
            gap: 1,
          }}
        >
          <Typography sx={{ minWidth: 140, mt: { xs: 0, sm: 'auto' } }}>電話：</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography>
              ご注文内容に関して <Link href="tel:070-7823-1009">070-7823-1009</Link>
            </Typography>
            <Typography>
              その他・問い合わせ <Link href="tel:070-2918-5781">070-2918-5781</Link>
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: 'flex-start',
            gap: 1,
          }}
        >
          <Typography sx={{ minWidth: 140 }}>メールアドレス：</Typography>
          <Typography>
            <Link href="mailto:sorakarabin@gg.jp.panasonic.com">
              sorakarabin@gg.jp.panasonic.com
            </Link>
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: 'flex-start',
            gap: 1,
          }}
        >
          <Typography sx={{ minWidth: 140 }}>受付時間：</Typography>
          <Typography>9:00-17:00 (土日祝祭日・年末年始を除く)</Typography>
        </Box>
      </Box>
    </Paper>
  );
};
