import { Agreement } from './Agreement';
export const SpecificTradeLaw = () => {
  const fileName = 'SpecificTradeLaw';
  return (
    <Agreement
      title="特定商取引に基づく表記"
      fileName={fileName}
      agreeFunction={() => {}}
    ></Agreement>
  );
};
