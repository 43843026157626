import React from 'react';
import ReactDOM from 'react-dom/client';
// import './css/main.scss';
import './index.css';
import App from './app/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
//import {UpdateDialog} from './Utils/Dialog';

if (process.env.NODE_ENV !== 'development' && process.env.REACT_APP_CONSOLELOG !== 'true') {
  console.log = () => {};
} else {
  console.info('development mode.');
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
// serviceWorkerRegistration.register({
//   onUpdate: (registration) => {
//     if (registration.waiting) {
//        ReactDOM.render(<UpdateDialog registration={registration} />, document.querySelector('.update-dialog'));
//     }
//   },
// });

const configuration = {
  onUpdate: (registration: ServiceWorkerRegistration) => {
    if (registration && registration.waiting) {
      // NOTE: ログインと重なると再度ログインが必要になるため、ログイン画面の場合は更新をスキップ
      if (window.location.pathname === '/login') {
        // 3秒ごとにパスが変わっているか確認
        const timer = setInterval(() => {
          if (window.location.pathname !== '/login' && registration.waiting) {
            clearInterval(timer);
            // eslint-disable-next-line no-alert
            window.alert('アプリが更新されました。 ページを再読込します。');
            registration.waiting.postMessage({ type: 'SKIP_WAITING' });
          }
        }, 3000);
        return;
      }
      // eslint-disable-next-line no-alert
      window.alert('アプリが更新されました。 ページを再読込します。');
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
  },
};

serviceWorkerRegistration.register(configuration);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// // どの画面でも右クリックを無効化
// document.oncontextmenu = function () {
//   return false;
// };
