import { DialogOk } from '@common/components/Dialog';
import {
  Backdrop,
  Button,
  CircularProgress,
  InputAdornment,
  Paper,
  TextField,
} from '@mui/material';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import IconButton from '@mui/material/IconButton';
import { ConfirmDialog } from './ConfirmDialog';

export const SignUp = () => {
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const [signInInfo, setSignInInfo] = useState({
    username: '',
    password: '',
  });

  type FormType = {
    mailAddress: string;
    phoneNumber: string;
    password: string;
  };
  const { handleSubmit, control } = useForm<FormType>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      mailAddress: '',
      phoneNumber: '',
      password: '',
    },
  });

  const onSubmit: SubmitHandler<FormType> = async (data) => {
    setProcessing(true);
    const formattedPhoneNumber = data.phoneNumber.replace(/-/g, '');
    try {
      const user = await Auth.signUp({
        username: data.mailAddress,
        password: data.password,
        attributes: {
          email: data.mailAddress,
          phone_number: '+81' + formattedPhoneNumber.replace('0', ''),
        },
      });
      setProcessing(false);
      setSignInInfo({
        username: user.user.getUsername(),
        password: data.password,
      });
      setIsOpenConfirmDialog(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: Error | any) {
      setProcessing(false);
      // サインアップ失敗時の処理
      if (error.code) {
        console.error(error);
        if (error.code === 'UsernameExistsException') {
          setMessage('既に登録済みのメールアドレスです');
        } else if (error.code === 'InvalidPasswordException') {
          setMessage('パスワードは、８文字以上で入力してください');
        } else if (error.code === 'InvalidParameterException') {
          setMessage('入力値に誤りがあります');
        } else if (error.code === 'UserLambdaValidationException') {
          setMessage('指定のドメインは許可されていません');
        } else {
          setMessage('想定外のエラーです');
          console.error(error);
        }
        setOpen(true);
      }
      return;
    }
  };
  return (
    <div>
      <Backdrop open={processing} sx={{ zIndex: 1000 }}>
        <CircularProgress />
      </Backdrop>
      <DialogOk
        open={open}
        callbackOk={() => setOpen(false)}
        title="エラー"
        message={message}
      ></DialogOk>
      <ConfirmDialog
        isOpen={isOpenConfirmDialog}
        username={signInInfo.username}
        password={signInInfo.password}
      />

      <div className="w-full mt-4 flex justify-center items-center">
        <Paper className="w-[22rem] p-8" elevation={6}>
          <p className="font-semibold text-xl">新規会員登録</p>
          <p className="mt-2 text-sm">
            メールアドレス、携帯電話番号、パスワード（８文字以上）を入力し
            <br />
            新規会員登録を行なってください。
          </p>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col items-center gap-4 mx-auto mt-1"
          >
            <Controller
              name="mailAddress"
              control={control}
              rules={{ required: { value: true, message: '入力必須項目です' } }}
              render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                <TextField
                  label="メールアドレス"
                  variant="standard"
                  size="medium"
                  className="w-full"
                  error={Boolean(error?.message)}
                  helperText={error?.message}
                  onChange={(e) => onChange(e.target.value.trimEnd())}
                  {...field}
                />
              )}
            />
            <Controller
              name="phoneNumber"
              control={control}
              rules={{
                required: { value: true, message: '入力必須項目です' },
                pattern: {
                  value: /^0\d{1}0-{0,1}\d{4}-{0,1}\d{4}$/,
                  message: '有効な携帯電話番号ではありません',
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="携帯電話番号"
                  variant="standard"
                  size="medium"
                  className="w-full"
                  error={Boolean(error?.message)}
                  helperText={error?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              rules={{ required: { value: true, message: '入力必須項目です' } }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="パスワード"
                  type={isVisiblePassword ? 'text' : 'password'}
                  variant="standard"
                  size="medium"
                  className="w-full"
                  error={Boolean(error?.message)}
                  helperText={error?.message}
                  {...field}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setIsVisiblePassword(!isVisiblePassword)}>
                          {isVisiblePassword ? (
                            <VisibilityOffRoundedIcon />
                          ) : (
                            <VisibilityRoundedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

            <div className="mt-2">
              <Button variant="contained" type="submit">
                新規登録
              </Button>
            </div>
          </form>
          <p
            className="text-sm mt-2 cursor-pointer underline text-primary"
            onClick={() => navigate('/login')}
          >
            登録済みの方はこちら
          </p>
        </Paper>
      </div>
    </div>
  );
};
