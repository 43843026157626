import { DialogOk } from '@common/components/Dialog';
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
} from '@mui/material';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import { userActions } from '@common/data/User';
import { DataWantedRoute } from '@common/data/WantedRoute';
import { ConfirmDialog } from './ConfirmDialog';

export const Login = () => {
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);
  const [open, setOpen] = useState(false);
  const [openNewPassword, setOpenNewPassword] = useState(false);
  const [openMfa, setOpenMfa] = useState(false);
  const [openUpdateSuccess, setOpenUpdateSuccess] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmInfo, setConfirmInfo] = useState({
    username: '',
    password: '',
  });
  const [message, setMessage] = useState('');
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [cognitoUser, setCognitoUser] = useState<any>(null);
  const [mfaType, setMfaType] = useState<'SMS_MFA' | 'SOFTWARE_TOKEN_MFA' | null>(null);
  const setLogin = userActions.useSetLogin();
  const wantedRoute = DataWantedRoute.useWantedRouteValue();

  type FormType = {
    mailAddress: string;
    password: string;
  };
  const { handleSubmit, control } = useForm<FormType>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      mailAddress: '',
      password: '',
    },
  });

  const onSubmit: SubmitHandler<FormType> = async (data) => {
    try {
      setProcessing(true);
      const user = await Auth.signIn(data.mailAddress, data.password);
      setProcessing(false);

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setCognitoUser(user);
        setOpenNewPassword(true);
        return;
      } else if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        setCognitoUser(user);
        setMfaType(user.challengeName);
        setOpenMfa(true);

        return;
      }

      setLogin();
      navigate(wantedRoute);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: Error | any) {
      setProcessing(false);
      // サインイン失敗時の処理
      if (error.code) {
        if (error.code === 'UserNotFoundException') {
          setMessage('ログインに失敗しました。メールアドレスまたはパスワードが正しくありません。');
        } else if (error.code === 'NotAuthorizedException') {
          setMessage('ログインに失敗しました。メールアドレスまたはパスワードが正しくありません。');
        } else if (error.code === 'UserNotConfirmedException') {
          // 認証が終わっていない場合は、認証コードを再送して認証ダイアログを表示する
          await Auth.resendSignUp(data.mailAddress);
          setConfirmInfo({
            username: data.mailAddress,
            password: data.password,
          });
          setOpenConfirmDialog(true);
          return;
        } else {
          setMessage('想定外のエラーです');
          console.error(error);
        }
        setOpen(true);
      }
      return;
    }
  };

  const newPasswordCallback = async (password: string) => {
    try {
      setProcessing(true);
      await Auth.completeNewPassword(
        cognitoUser,
        password,
        cognitoUser.challengeParam.requiredAttributes
      );
      setProcessing(false);
      setOpenNewPassword(false);
      setOpenUpdateSuccess(true);
      setLogin();
    } catch (e) {
      setProcessing(false);
      console.error(e);
      setMessage('想定外のエラーです');
      setOpen(true);
    }
  };

  const mfaCallback = async (code: string) => {
    try {
      setProcessing(true);
      await Auth.confirmSignIn(cognitoUser, code, mfaType);
      setOpenMfa(false);
      setLogin();
      // レンダリング後に画面遷移するため、settimeoutを使用
      setTimeout(() => {
        navigate(wantedRoute, { replace: true });
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: Error | any) {
      setProcessing(false);
      // サインイン失敗時の処理
      if (error.code) {
        if (error.code === 'CodeMismatchException') {
          setMessage('コードが不正です、再度入力してください');
        } else {
          setMessage('想定外のエラーです');
          console.error(error);
        }
        setOpen(true);
      }
      return;
    }
  };

  return (
    <div>
      <Backdrop open={processing} sx={{ zIndex: 10000 }}>
        <CircularProgress />
      </Backdrop>
      <ConfirmDialog
        isOpen={openConfirmDialog}
        username={confirmInfo.username}
        password={confirmInfo.password}
      />
      <DialogOk
        open={open}
        callbackOk={() => setOpen(false)}
        title="エラー"
        message={message}
      ></DialogOk>
      <DialogOk
        open={openUpdateSuccess}
        callbackOk={() => {
          setOpenUpdateSuccess(false);
          navigate('/');
        }}
        title="パスワード更新"
        message="パスワードの更新に成功しました。ログインします。"
      ></DialogOk>
      <NewPasswordDialog isOpen={openNewPassword} newPasswordCallback={newPasswordCallback} />
      <MfaDialog mfaType={mfaType} isOpen={openMfa} mfaCallback={mfaCallback} />

      <div className="w-full flex justify-center mt-4">
        <Paper className="w-[22rem] p-8" elevation={6}>
          <p className="font-semibold text-xl">会員のお客様</p>
          <p className="mt-2 text-sm">
            メールアドレスとパスワードを入力して
            <br />
            ログインしてください。
          </p>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col items-center gap-4 mx-auto mt-1"
          >
            <Controller
              name="mailAddress"
              control={control}
              rules={{ required: { value: true, message: '入力必須項目です' } }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="メールアドレス"
                  variant="standard"
                  size="medium"
                  className="w-full"
                  error={Boolean(error?.message)}
                  helperText={error?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              rules={{ required: { value: true, message: '入力必須項目です' } }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="パスワード"
                  variant="standard"
                  size="medium"
                  className="w-full"
                  type={isVisiblePassword ? 'text' : 'password'}
                  error={Boolean(error?.message)}
                  helperText={error?.message}
                  {...field}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setIsVisiblePassword(!isVisiblePassword)}>
                          {isVisiblePassword ? (
                            <VisibilityOffRoundedIcon />
                          ) : (
                            <VisibilityRoundedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <div className="mt-2">
              <Button variant="contained" type="submit">
                ログイン
              </Button>
            </div>
          </form>
          <p
            className="text-sm mt-2 cursor-pointer underline text-primary"
            onClick={() => navigate('/forgotPassword')}
          >
            パスワードをお忘れの方はこちら
          </p>
          <hr className="my-6" />
          <p className="font-semibold text-xl">初めてご利用の方</p>
          <p className="mt-2 text-sm">
            初めてご利用のお客様は
            <br />
            こちらから会員登録を行って下さい。
          </p>
          <div className="mt-2 text-center">
            <Button variant="contained" onClick={() => navigate('/signUp')}>
              新規会員登録
            </Button>
          </div>
        </Paper>
      </div>
    </div>
  );
};

const NewPasswordDialog = ({
  isOpen,
  newPasswordCallback,
}: {
  isOpen: boolean;
  newPasswordCallback: (password: string) => void;
}) => {
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);

  type FormType = {
    password: string;
  };
  const { handleSubmit, control } = useForm<FormType>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      password: '',
    },
  });
  const onSubmit: SubmitHandler<FormType> = async (data) => {
    await newPasswordCallback(data.password);
  };
  return (
    <Dialog open={isOpen} fullWidth>
      <DialogTitle className="text-lg" sx={{ pt: 2, pb: 0 }}>
        新しいパスワードを入力
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <div className="text-sm">
            <p>パスワードを更新する必要があります。</p>
            <p>新しいパスワードを入力してください。</p>
          </div>
          <div className="mx-auto mt-2">
            <Controller
              name="password"
              control={control}
              rules={{ required: { value: true, message: '入力必須項目です' } }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="パスワード"
                  variant="standard"
                  size="medium"
                  className="w-full"
                  type={isVisiblePassword ? 'text' : 'password'}
                  error={Boolean(error?.message)}
                  helperText={error?.message}
                  {...field}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setIsVisiblePassword(!isVisiblePassword)}>
                          {isVisiblePassword ? (
                            <VisibilityOffRoundedIcon />
                          ) : (
                            <VisibilityRoundedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" className="mt-auto" type="submit">
            更新
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const MfaDialog = ({
  mfaType,
  isOpen,
  mfaCallback,
}: {
  mfaType: 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA' | null;
  isOpen: boolean;
  mfaCallback: (code: string) => void;
}) => {
  type FormType = {
    code: string;
  };
  const { handleSubmit, control } = useForm<FormType>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      code: '',
    },
  });
  const onSubmit: SubmitHandler<FormType> = async (data) => {
    await mfaCallback(data.code);
  };
  return (
    <Dialog open={isOpen} fullWidth>
      <DialogTitle className="text-lg" sx={{ pt: 2, pb: 0 }}>
        認証コード入力
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <div className="text-sm">
            {mfaType === 'SMS_MFA' && <p>お使いの携帯電話番号に送信された</p>}
            {mfaType === 'SOFTWARE_TOKEN_MFA' && <p>お使いのアプリに表示された</p>}
            <p>認証コードを入力してください。</p>
          </div>
          <div className="mx-auto mt-2">
            <Controller
              name="code"
              control={control}
              rules={{ required: { value: true, message: '入力必須項目です' } }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="認証コード"
                  variant="standard"
                  size="medium"
                  className="w-full"
                  error={Boolean(error?.message)}
                  helperText={error?.message}
                  {...field}
                />
              )}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" className="mt-auto" type="submit">
            送信
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
