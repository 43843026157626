import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

// MUI
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
// MUI Icon
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import WorkHistoryRoundedIcon from '@mui/icons-material/WorkHistoryRounded';
// import ContactMailRoundedIcon from '@mui/icons-material/ContactMailRounded';
import ContactSupportRoundedIcon from '@mui/icons-material/ContactSupportRounded';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import InfoIcon from '@mui/icons-material/Info';

// MUI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

//Version
import { MyVersion } from './MyVersion';

type DialogSignOutProps = {
  readonly open: boolean;
  readonly callbackOk: () => void;
  readonly callbackCancel: () => void;
};
export const DialogSignOut = ({ open, callbackOk, callbackCancel }: DialogSignOutProps) => {
  return (
    <Dialog open={open} onClose={callbackCancel}>
      <DialogTitle>ログアウトしますか？</DialogTitle>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button variant="contained" onClick={callbackOk} autoFocus>
          OK
        </Button>
        <Button variant="outlined" onClick={callbackCancel}>
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export function Menu() {
  const navigate = useNavigate();
  const [openTerms, setOpenTerms] = useState(false);
  const [openSignOutDialog, setOpenSignOutDialog] = useState(false);

  const signOutFunc = async () => {
    await Auth.signOut();
    navigate('/');
    navigate(0);
  };

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <List>
        {/* <ListItem>
          <ListItemButton onClick={() => navigate('/notifications')}>
            <ListItemIcon>
              <Badge badgeContent={2} color="primary">
                <NotificationsIcon fontSize="large" color="primary" />
              </Badge>
            </ListItemIcon>
            <ListItemText primary="お知らせ" />
            <NavigateNextRoundedIcon />
          </ListItemButton>
        </ListItem>
        <Divider /> */}

        <ListItem>
          <ListItemButton onClick={() => navigate('/order/history')}>
            <ListItemIcon>
              <WorkHistoryRoundedIcon fontSize="large" color="primary" />
            </ListItemIcon>
            <ListItemText primary="注文履歴" />
            <NavigateNextRoundedIcon />
          </ListItemButton>
        </ListItem>
        <Divider />

        <ListItem>
          <ListItemButton onClick={() => navigate('/user/info')}>
            <ListItemIcon>
              <AccountBoxRoundedIcon fontSize="large" color="primary" />
            </ListItemIcon>
            <ListItemText primary="アカウント情報" />
            <NavigateNextRoundedIcon />
          </ListItemButton>
        </ListItem>
        <Divider />

        <ListItem>
          <ListItemButton onClick={() => navigate('/contact')}>
            <ListItemIcon>
              <ContactSupportRoundedIcon fontSize="large" color="primary" />
            </ListItemIcon>
            <ListItemText primary="お問い合わせ" />
            <NavigateNextRoundedIcon />
          </ListItemButton>
        </ListItem>
        <Divider />

        <ListItem>
          <ListItemButton onClick={() => setOpenTerms(!openTerms)}>
            <ListItemIcon>
              <ArticleRoundedIcon fontSize="large" color="primary" />
            </ListItemIcon>
            <ListItemText primary="規約等" />
            {openTerms ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
          </ListItemButton>
        </ListItem>
        <Divider />

        <Collapse in={openTerms} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ ml: 2 }}>
            <ListItem>
              <ListItemButton onClick={() => navigate('/agreement/service')}>
                <ListItemIcon>
                  <ArticleRoundedIcon fontSize="large" color="primary" />
                </ListItemIcon>
                <ListItemText primary="利用規約" />
              </ListItemButton>
            </ListItem>
            <Divider />

            <ListItem>
              <ListItemButton onClick={() => navigate('/agreement/policy')}>
                <ListItemIcon>
                  <ArticleRoundedIcon fontSize="large" color="primary" />
                </ListItemIcon>
                <ListItemText primary="プライバシーポリシー" />
              </ListItemButton>
            </ListItem>
            <Divider />

            <ListItem>
              <ListItemButton onClick={() => navigate('/agreement/specificTradeLaw')}>
                <ListItemIcon>
                  <ArticleRoundedIcon fontSize="large" color="primary" />
                </ListItemIcon>
                <ListItemText primary="特定商取引法に基づく表記" />
              </ListItemButton>
            </ListItem>
            <Divider />
          </List>
        </Collapse>

        <ListItem>
          <ListItemButton onClick={() => setOpenSignOutDialog(true)}>
            <ListItemIcon>
              <LogoutRoundedIcon fontSize="large" color="primary" />
            </ListItemIcon>
            <ListItemText primary="ログアウト" />
          </ListItemButton>
        </ListItem>
        <Divider />
        <DialogSignOut
          open={openSignOutDialog}
          callbackOk={signOutFunc}
          callbackCancel={() => setOpenSignOutDialog(false)}
        />

        <ListItem>
          <ListItemButton>
            <ListItemIcon>
              <InfoIcon fontSize="large" color="primary" />
            </ListItemIcon>
            <ListItemText primary={MyVersion.Version} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
}
