import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Badge from '@mui/material/Badge';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import { dataShopItem } from '@common/data/ShopItem';
import { userActions } from '@common/data/User';

export const ShopCategory = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { categoryId } = useParams();
  if (!categoryId) return <></>;

  return (
    <React.Suspense
      fallback={
        <div className="w-full h-screen flex justify-center items-center">
          <CircularProgress />
        </div>
      }
    >
      <ShopCategory2 categoryId={categoryId} />
    </React.Suspense>
  );
};

const ShopCategory2 = ({ categoryId }: { categoryId: string }) => {
  const navigate = useNavigate();
  const items = dataShopItem.category
    .useCategoryItemsInStock(categoryId)
    .concat(dataShopItem.category.useCategoryItemsSoldOut(categoryId));
  const categoryName = dataShopItem.category.useCategoryName(categoryId);
  const cognitoUserInfo = userActions.useCognitoUserInfo();

  return (
    <div className="p-4">
      <div className="text-2xl mb-4">
        <MenuBookRoundedIcon className="mx-2" />
        {categoryName}
      </div>

      <div className="grid gap-4 grid-cols-2 sm:grid-cols-3 md:grid-cols-4">
        {items.map((item) => (
          <div
            key={item.itemId}
            className="flex flex-col relative rounded-md shadow cursor-pointer"
            onClick={() => navigate('/shop/items/' + item.itemId)}
          >
            <div>
              <div className="h-40 w-full relative">
                <img
                  alt="商品画像"
                  src={item.image[0]}
                  className="h-full w-full object-cover rounded-t-md"
                />
                {item.stockCount <= 0 && (
                  <div className="absolute bottom-0 left-0 right-0 px-2 text-white bg-black/75">
                    <div className="font-bold">売り切れ</div>
                  </div>
                )}
                {item.stockCount > 0 && item.stockCount <= (item.limitedStock ?? 1) && (
                  <div className="absolute bottom-0 left-0 right-0 px-2 text-white bg-warning/50">
                    <div className="font-bold">残り僅か</div>
                  </div>
                )}
              </div>
              <div className="m-2 grow flex flex-col">
                <div className="text-sm font-bold text-primary-light line-clamp-1">
                  {item.brand}
                </div>
                <div className="grow text-base line-clamp-2 lg:line-clamp-1">{item.name}</div>
                {cognitoUserInfo.groups.includes('Developer') && (
                  <Badge badgeContent={item.stockCount} color="secondary" max={100000}></Badge>
                )}
                <div className="mt-1 text-right">
                  <span className="text-lg font-bold">{item.price.toLocaleString()}</span>
                  <span className="text-xs"> 円（税込）</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
