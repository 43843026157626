import { Step, StepLabel, Stepper } from '@mui/material';

export function OrderStepper({ activeStep }: { activeStep: number }) {
  return (
    <div className="hidden ssm:block">
      <Stepper className="pt-2 w-full" alternativeLabel activeStep={activeStep}>
        <Step key="step1">
          <StepLabel>
            <span className="text-xs sm:text-sm">
              カート
              <br className="sm:hidden" />
              確認
            </span>
          </StepLabel>
        </Step>
        <Step key="step2">
          <StepLabel>
            <span className="text-xs sm:text-sm">
              お届け
              <br className="sm:hidden" />
              先確認
            </span>
          </StepLabel>
        </Step>
        <Step key="step3">
          <StepLabel>
            <span className="text-xs sm:text-sm">
              お届け
              <br className="sm:hidden" />
              時間
              <br className="sm:hidden" />
              選択
            </span>
          </StepLabel>
        </Step>
        <Step key="step4">
          <StepLabel>
            <span className="text-xs sm:text-sm">
              ご注文
              <br className="sm:hidden" />
              内容
              <br className="sm:hidden" />
              確認
            </span>
          </StepLabel>
        </Step>
        <Step key="step5">
          <StepLabel>
            <span className="text-xs sm:text-sm">
              注文
              <br className="sm:hidden" />
              完了
            </span>
          </StepLabel>
        </Step>
      </Stepper>
    </div>
  );
}
