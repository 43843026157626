import { selectorFamily, useRecoilValue, useRecoilCallback, atomFamily } from 'recoil';
import { RecoilKeys } from './RecoilKeys';
import { api } from './Api';
import { shopSelected } from './Shop';
import { useEffect } from 'react';
import { CONST } from '@common/global';

export type AppMessage = {
  messageId: string;
  message: string;
};

const appMessagesAllAtom = atomFamily<AppMessage[], string>({
  key: RecoilKeys.APP_MESSAGES_STATE_ALL,
  effects: (shopId: string) => [
    ({ setSelf, trigger }) => {
      if (trigger === 'get') {
        const appMessagesMaster = api.fetchAppMessages(shopId);
        setSelf(appMessagesMaster);
      }
    },
  ],
});

const appMessage = selectorFamily({
  key: RecoilKeys.APP_MESSAGES_MESSAGE,
  get:
    (messageId) =>
    ({ get }) => {
      const selectedShop = get(shopSelected);
      const message = get(appMessagesAllAtom(selectedShop.shopId)).find(
        (message) => message.messageId === messageId
      );
      return message ? message.message : '';
    },
});

const useAppMessagesRefresh = () =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        const selectedShop = await snapshot.getPromise(shopSelected);
        set(
          appMessagesAllAtom(selectedShop.shopId),
          await api.fetchAppMessages(selectedShop.shopId)
        );
      },
    []
  );

const useAppMessagesRefreshInterval = () => {
  const refresh = useAppMessagesRefresh();
  useEffect(() => {
    const intervalId = setInterval(refresh, CONST.TIME_APP_MSG_REFRESH_INTERVAL);
    return () => clearInterval(intervalId);
  }, []);
};

// export
export const dataAppMessages = {
  useAppMessage: (messageId: string) => useRecoilValue(appMessage(messageId)),
  useAppMessagesRefresher: () => useAppMessagesRefresh(),
  useAppMessagesRefreshInterval,
};
