import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { agreementActions } from '@common/data/Agreement';
import { userActions } from '@common/data/User';
import { Agreement } from './Agreement';
import { DataWantedRoute } from '@common/data/WantedRoute';

export const TermsOfUse = () => {
  const user = userActions.useGetUser();
  const saveUserFunc = userActions.useStoreUser();
  const termsOfUse = agreementActions.useFindAgreement('termsOfUse');
  const processing = useRef(false);
  const navigate = useNavigate();
  const wantedRoute = DataWantedRoute.useWantedRouteValue();

  const fileName = 'TermsOfUse';

  const agreeFunction = async () => {
    if (processing.current) return;

    processing.current = true;
    await saveUserFunc({ ...user, termsOfUse: termsOfUse?.version ?? '' });
    processing.current = false;

    // MEMO: React18のレンダリング制御により、下記のようにしないとnavigateが
    // 想定通りに動作しない
    setTimeout(() => {
      navigate(wantedRoute);
    });
  };
  return <Agreement title="利用規約" fileName={fileName} agreeFunction={agreeFunction}></Agreement>;
};
