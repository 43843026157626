import React from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
// MUI
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { dataOrder, OrderState, OrderItem } from '@common/data/Order';
import { dataShopItem } from '@common/data/ShopItem';

export const OrderHistory = () => {
  const navigate = useNavigate();
  const history = _.sortBy(dataOrder.history.useOrderHistory(), 'timePaying').reverse();
  const displayableOrders = history.filter((order) => isDisplayableOrderState(order.state));
  return (
    <React.Suspense>
      <div className="w-full p-4">
        {displayableOrders.length ? (
          <Stack spacing={2}>
            {displayableOrders.map((order) => (
              <div
                key={order.orderId}
                onClick={() => navigate('/order/orders/' + order.orderId)}
                className="flex w-full h-24 items-center cursor-pointer rounded shadow shadow-gray-300"
              >
                <div className="font-bold p-8">
                  <ShowOrderState state={order.state} />
                </div>
                <div className="flex-1">
                  注文日: {order.timePaying.toLocaleDateString()}
                  <br />
                  {order.totalCount} 点のご注文
                </div>
                <OrderImage items={order.items} />
              </div>
            ))}
          </Stack>
        ) : (
          <Stack spacing={2} className="text-center pt-2">
            <div>注文履歴はありません。</div>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate('/')}
                className="w-80"
              >
                商品一覧へ
              </Button>
            </div>
          </Stack>
        )}
      </div>
    </React.Suspense>
  );
};

// switch文の場合、複雑度が高くなるため、eslintのエラーを無視する
// eslint-disable-next-line complexity
const ShowOrderState = ({ state }: { state: OrderState }) => {
  switch (state) {
    case 'paying':
      return <span className="">決済待</span>;
    case 'credited':
      return <span className="">受付待</span>;
    case 'assigned':
      return <span className="">出荷待</span>;
    case 'accepted':
      return <span className="">出荷待</span>;
    case 'onDelivery':
      return <Box sx={{ color: 'warning.main' }}>配達中</Box>;
    case 'arrived':
      return <Box sx={{ color: 'warning.main' }}>配達中</Box>;
    case 'posted':
      return <Box sx={{ color: 'warning.main' }}>受取待</Box>;
    case 'completed':
      return <span className="">受取済</span>;
    case 'payingTimeout':
      return <span className="">保留中</span>;
    case 'failed':
      return <span className="">決済失敗</span>;
    case 'cancelled':
      return <span className="text-gray-400">キャンセル済</span>;
    case 'waitingCustomerByStockOut':
      return <span className="text-gray-400">欠品連絡中</span>;
  }
};

const isDisplayableOrderState = (state: string) => {
  const displayableOrderState = [
    'credited',
    'assigned',
    'accepted',
    'onDelivery',
    'arrived',
    'posted',
    'completed',
    'cancelled',
    'waitingCustomerByStockOut',
  ]; // 'pre-order', 'failed'の際は注文履歴に表示しない
  return displayableOrderState.includes(state);
};

// 1番目の商品画像を表示する
// 複数の画像を表示するようにしたい
const OrderImage = ({ items }: { items: OrderItem[] }) => {
  const img = dataShopItem.itemMaster.useShopItemMaster(items[0]?.itemId)?.image[0];
  return <img alt="" src={img} className="object-cover h-full w-1/4 rounded" />;
};
