import { useLayoutEffect, useState } from 'react';

// レスポンシブ対応
export const useJudgeSize = () => {
  const [isSp, setIsSp] = useState(false);
  useLayoutEffect(() => {
    const updateSize = (): void => {
      if (window.innerWidth <= 768) {
        setIsSp(true);
        return;
      }
      setIsSp(false);
    };

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return isSp;
};
