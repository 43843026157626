import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { dataOrder } from '@common/data/Order';
import { Banner } from './Banner';

// MUI
import { OrderStepper } from './OrderStepper';

export default function Success() {
  // ブラウザバックを無効化
  window.history.pushState(null, '', window.location.href);

  const { orderId } = useParams();
  console.log(orderId);
  const deliveryTime = dataOrder.deliveryTime.useDeliveryTimeOptions();
  const clearCart = dataOrder.cart.useClearCart();
  const [isClear, setIsClear] = useState(false);

  // NOTE: clearCartの内部でdeliveryTimeのsnapshotを使用しているが
  // APIのレスポンスが返ってくる前に発火するとデフォルト値が返却され、エラーとなるため下記対応
  useEffect(() => {
    const clear = async () => {
      if (deliveryTime.length === 0) return;
      if (isClear) return;
      await clearCart();
      setIsClear(true);
    };
    clear();
  }, [isClear, deliveryTime, clearCart]);

  // /order/orders/19792804-4172-11ed-a26c-91fff0e00824
  const navigate = useNavigate();
  return (
    <>
      <Banner />
      <main style={{ padding: '1rem 0' }}>
        <OrderStepper activeStep={5} />
        <br />
        <div className="w-full h-3/1 flex flex-col items-center justify-center">
          <div className="text-2xl text-center">ご注文を受け付けました。</div>
        </div>

        <div className="py-3 my-1 mb-3 text-1xl text-left ">
          この度はソラカラ便をご利用いただきありがとうございます。
          「配達状況」から配達・受取についての確認ができます。
        </div>
        <div
          className="fixed bottom-0 left-0 right-0 p-2 pb-16 flex flex-col items-center
        text-primaryContrast"
        >
          <div
            className="w-full py-3 my-1 mb-3 rounded-full cursor-pointer
        text-center text-2xl font-bold text-primary border-primary border-2"
            onClick={() => {
              navigate(`/order/orders/${orderId}`);
            }}
          >
            配達状況を確認する
          </div>
          <div
            className="w-full py-3 rounded-full cursor-pointer
        text-center text-2xl font-bold text-white bg-primary"
            onClick={() => {
              navigate('/');
            }}
          >
            ストアへ戻る
          </div>
        </div>
      </main>
    </>
  );
}
