import { dataOrder } from '@common/data/Order';
import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Price } from '@common/components/Price';
import { OrderStepper } from './OrderStepper';
import { api } from '@common/data/Api';
import React from 'react';
import { ItemMessage } from './Cart';
import { dataDeliveryTime } from '@common/data/OrderDeliveryTime';
import { DialogOk } from '@common/components/Dialog';
import { userActions } from '@common/data/User';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export function OrderConfirm() {
  return (
    <Box sx={{ width: '100%', p: 1, pb: 16 }}>
      <OrderStepper activeStep={3} />
      <ConfirmDescription />
      <Divider />
      <DeliveryTime />
      <Divider />
      <DeliveryBox />
      <Divider />
      <CartItems />
      <Divider />
      <CartPrice />
      <Divider />
      <PurchasePanel></PurchasePanel>
    </Box>
  );
}

function ConfirmDescription() {
  return (
    <div className="mt-4">
      <Typography variant="h5" className="text-center">
        ご注文内容確認
      </Typography>
      <p className="my-4 mx-2 break-words">
        以下の内容でご注文を受け付けます。これ以降お支払いの手続きとなり商品の変更はできません。
      </p>
    </div>
  );
}

function CartItems() {
  const cartItems = dataOrder.cart.useCartItems();
  return (
    <Box className="py-4">
      <Typography variant="h5">注文商品</Typography>
      <Box className="pt-2">
        {cartItems.map((item, index) => (
          <div
            key={item.itemId}
            className="flex flex-row mb-2 w-full h-28 items-center rounded-md shadow"
          >
            <div className="w-1/4 h-full">
              <img
                alt="商品画像"
                src={item.image[0]}
                className="w-full h-full object-cover rounded-l-md"
              />
            </div>
            <div className="pl-4 pt-2 pb-0 h-full grow flex flex-col">
              <div className="grow">
                <div className="grow text-base line-clamp-2"> {item.name} </div>
              </div>
              <div className="text-right">
                <Price price={item.price} />

                <span className="text-lg font-bold ml-2">{item.count}</span>
                <span className="text-xs mr-2"> 個</span>
              </div>
              <React.Suspense fallback={<></>}>
                <ItemMessage index={index} itemId={item.itemId} itemCount={item.count} />
              </React.Suspense>
            </div>
          </div>
        ))}
      </Box>
    </Box>
  );
}

function DeliveryBox() {
  const deliveryTo = dataOrder.deliveryTo.useSpotSelected();

  return (
    <Box className="py-4">
      <Typography variant="h5">お届け先</Typography>
      <Box className="pt-2 pb-4">
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={4}>
            {deliveryTo.image.length !== 0 ? (
              <img alt="" src={deliveryTo.image[0]} className="rounded-md" />
            ) : (
              <p>配送先画像なし</p>
            )}
          </Grid>
          <Grid item xs={7}>
            {deliveryTo.spotName}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

function DeliveryTime() {
  const deliveryTime = dataOrder.deliveryTime.useDeliveryTimeState();
  const isAvailableDeliveryTime = dataDeliveryTime.useIsAvailableDeliveryTimeSlot();
  return (
    <Box className="mt-4">
      <Typography variant="h5">お届け時間</Typography>
      <Box className="py-2">
        {deliveryTime ? (
          isAvailableDeliveryTime ? (
            <>
              <div>お届け日 : {deliveryTime.toStringDate()}</div>
              <div className="pt-2">お届け時間 : {deliveryTime.toStringTime(' - ')}</div>
            </>
          ) : (
            <>
              <div>お届け日 : {deliveryTime.toStringDate()}</div>
              <div className="pt-2">お届け時間 : {deliveryTime.toStringTime(' - ')}</div>
              <span>ご指定のお届け時間に空きがございません</span>
            </>
          )
        ) : (
          <span>お届け時間が選択されておりません</span>
        )}
      </Box>
    </Box>
  );
}

function CartPrice() {
  const cartTotalCount = dataOrder.cart.useCartTotalCount();
  const cartPrice = dataOrder.cart.useCartPrice();
  const deliveryFee = dataOrder.deliveryFee.useDeliveryFee();
  const discount = dataOrder.discount.useDiscount();
  return (
    <div className="mt-2">
      <Typography variant="h5">お支払い金額</Typography>

      <Stack direction="column" spacing={0.5} className="p-2">
        <Box className="flex mx-auto" sx={{ color: 'red', fontWeight: 'bold' }}>
          <span>＼</span>
          <span className="mx-4">送料無料キャンペーン中</span>
          <span>／</span>
        </Box>
        <Box className="flex">
          商品合計 ({cartTotalCount}点) :
          <Box className="ml-auto">
            {cartPrice.toLocaleString()}
            <span className="text-xs"> 円(税込)</span>
          </Box>
        </Box>
        <Box className="flex">
          送料:
          <Box className="ml-auto">
            {deliveryFee.toLocaleString()}
            <span className="text-xs"> 円(税込)</span>
          </Box>
        </Box>
        {discount < 0 && (
          <Box className="flex">
            割引:
            <Box className="ml-auto" sx={{ color: 'red', fontWeight: 'bold' }}>
              {discount.toLocaleString()}
              <span className="text-xs"> 円(税込)</span>
            </Box>
          </Box>
        )}
        <Box className="flex">
          お支払い合計金額：
          <Box className="ml-auto">
            <Price price={cartPrice + deliveryFee + discount} />
          </Box>
        </Box>
      </Stack>
    </div>
  );
}

function PurchasePanel() {
  const isAvailableDeliveryTime = dataDeliveryTime.useIsAvailableDeliveryTimeSlot();
  const placeOrderSkippingPayment = api.usePlaceOrderSkippingPayment();
  const gotoPayment = api.useGotoPayment();
  const [openProgress, setOpenProgress] = useState(false);
  const [openNavigateToCart, setOpenNavigateToCart] = useState(false);
  const [openNavigateToSelectTime, setOpenNavigateToSelectTime] = useState(false);
  const [openNavigateToDeliverySpot, setOpenNavigateToDeliverySpot] = useState(false);
  const [openUnKonwnError, setopenUnKonwnError] = useState(false);
  const navigate = useNavigate();
  const cartReady = dataOrder.cart.useIsCartReadyToBuy();
  const cognitoUserInfo = userActions.useCognitoUserInfo();
  const spotRefresher = dataOrder.deliveryTo.useSpotRefresher();
  const refreshCap = dataOrder.deliveryTime.useDeliveryTimeOptionRefresher();

  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = React.useState(false);
  const openConfirmDialog = () => {
    setIsOpenConfirmDialog(true);
  };
  const closeConfirmDialog = () => {
    setIsOpenConfirmDialog(false);
  };

  function goError(msg?: { reason: string }) {
    if (msg) {
      console.log(msg.reason);
      if (msg.reason === 'CAPACITY_EXPIRED') {
        setOpenNavigateToSelectTime(true);
      } else if (msg.reason === 'OUT_OF_CAPACITY') {
        setOpenNavigateToSelectTime(true);
      } else if (msg.reason === 'OUT_OF_STOCK') {
        setOpenNavigateToCart(true);
      } else if (msg.reason === 'INVALID_DELIVERY_SPOT_ID') {
        setOpenNavigateToDeliverySpot(true);
      } else if (msg.reason === 'INVALID_ITEM_ID') {
        setOpenNavigateToCart(true);
      } else {
        setopenUnKonwnError(true);
      }
      console.log('order failed: ' + msg.reason);
    }
    setOpenProgress(false);
  }

  const orderWithPayment = async () => {
    setOpenProgress(true);
    const msg = await gotoPayment();
    goError(msg);
  };

  const orderCompleteSkippingPayment = async () => {
    setOpenProgress(true);

    const msg = await placeOrderSkippingPayment();
    // setOpenProgress(false);
    if (msg && msg?.status === 'ok') {
      navigate('/order/completed/' + msg.order.orderId);
    } else {
      goError(msg);
    }
  };

  // pb:7 は MyBottomNavigation に隠れる高さ
  return (
    <>
      <ConfirmDialog
        isOpen={isOpenConfirmDialog}
        paymentCallback={orderWithPayment}
        closeCallback={closeConfirmDialog}
      />
      <Paper
        square
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          p: 1,
          pb: 8,
        }}
      >
        <Stack direction="column" spacing={2} justifyContent="flex-end">
          {cartReady ? (
            isAvailableDeliveryTime ? (
              <>
                <p className="text-center">
                  <Link className="text-primary underline" to="/agreement/service">
                    利用規約
                  </Link>
                  と
                  <Link className="text-primary underline" to="/agreement/policy">
                    プライバシーポリシー
                  </Link>
                  に同意して
                </p>
                <div className="flex flex-row">
                  {cognitoUserInfo.groups.includes('Developer') ? (
                    <>
                      <div
                        className="w-1/2 py-3 rounded-full cursor-pointer
                  text-center text-xl font-bold text-white bg-secondary"
                        onClick={() => orderCompleteSkippingPayment()}
                      >
                        支払スキップ
                      </div>
                      <div
                        className="w-1/2 py-3 rounded-full cursor-pointer
                  text-center text-xl font-bold text-white bg-primary"
                        onClick={() => openConfirmDialog()}
                      >
                        お支払方法を選択
                      </div>
                    </>
                  ) : (
                    <div
                      className="w-full py-3 rounded-full cursor-pointer
                  text-center text-2xl font-bold text-white bg-primary"
                      onClick={() => openConfirmDialog()}
                    >
                      お支払方法を選択
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div
                className="w-full py-3 rounded-full cursor-pointer
                  text-center text-2xl font-bold text-warningContrast bg-warning"
                onClick={() => navigate('/order/select_time')}
              >
                お届け時間を選択
              </div>
            )
          ) : (
            <div
              className="w-full py-3 rounded-full cursor-pointer
                  text-center text-2xl font-bold text-warningContrast bg-warning"
              onClick={() => navigate('/cart')}
            >
              カートの内容をご確認ください
            </div>
          )}
        </Stack>
        <DialogOk
          open={openNavigateToCart}
          message="カートの内容をご確認ください"
          callbackOk={() => navigate('/cart')}
        />
        <DialogOk
          open={openNavigateToSelectTime}
          message="選択されたお届け時間の枠を確保できませんでした。お届け時間を再度選択してください"
          callbackOk={() => {
            refreshCap();
            navigate('/order/select_time');
          }}
        />
        <DialogOk
          open={openNavigateToDeliverySpot}
          message="お届け先を再度選択してください"
          callbackOk={() => {
            spotRefresher();
            navigate('/order/select_box');
          }}
        />
        <DialogOk
          open={openUnKonwnError}
          title="原因不明のエラーが発生しました"
          message="注文できませんでした"
          callbackOk={() => {
            navigate('/order/cart');
          }}
        />
        <Backdrop open={openProgress}>
          <CircularProgress />
        </Backdrop>
      </Paper>
    </>
  );
}

const ConfirmDialog = ({
  isOpen,
  paymentCallback,
  closeCallback,
}: {
  isOpen: boolean;
  paymentCallback: () => void;
  closeCallback: () => void;
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        closeCallback();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'お支払いについての注意事項'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          お支払い画面ではブラウザの戻る機能、再読み込みを使用しないでください。処理が正常に行われない場合があります。また、PayPayでのクレジットカード払いには対応しておりません。
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            closeCallback();
            paymentCallback();
          }}
        >
          お支払へ進む
        </Button>
      </DialogActions>
    </Dialog>
  );
};
