import { Button, CircularProgress, Paper, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { userActions } from '@common/data/User';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { DialogOk } from '@common/components/Dialog';

type FormType = {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
};

type Props = {
  title: string;
  btnText: string;
  navigateUrl?: string;
};

export const PasswordForm = ({ title, btnText, navigateUrl }: Props) => {
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [open, setOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();

  const { handleSubmit, control, watch, reset } = useForm<FormType>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
    },
  });
  // formの値の監視
  const allWatch = watch();

  useEffect(() => {
    if (
      allWatch.oldPassword === '' ||
      allWatch.newPassword === '' ||
      allWatch.newPasswordConfirm !== allWatch.newPassword
    ) {
      setDisabledBtn(true);
      return;
    } else {
      setDisabledBtn(false);
    }
  }, [allWatch]);

  const onSubmit: SubmitHandler<FormType> = async (data) => {
    if (processing) return;

    setProcessing(true);
    userActions
      .useStorePassword(data.oldPassword, data.newPassword)
      .then((res) => {
        console.log('パスワードを変更できました', res);
        reset();
        setDialogMessage('パスワードを変更しました');
        setOpen(true);
      })
      .catch((err) => {
        console.log('パスワードが変更できませんでした', err);
        setDialogMessage(`パスワードを変更できませんでした ${err.message}`);
        setOpen(true);
      });

    setProcessing(false);

    if (navigateUrl) {
      // MEMO: React18のレンダリング制御により、下記のようにしないとnavigateが
      // 想定通りに動作しない
      setTimeout(() => {
        navigate(navigateUrl);
      });
      return;
    }
  };

  return (
    <>
      <DialogOk
        open={open}
        message={dialogMessage}
        callbackOk={() => setOpen(false)}
        callbackOnClose={() => setOpen(false)}
      />

      <Stack spacing={2} alignItems={'center'} className="p-5">
        <Typography variant="h5" className="text-center">
          {title}
        </Typography>
        <Paper className="w-full sm:w-[500px]">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col items-center gap-4 w-4/5 mx-auto my-2"
          >
            <Controller
              name="oldPassword"
              control={control}
              rules={{ required: { value: true, message: '入力必須項目です' } }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="現在のパスワード"
                  variant="standard"
                  size="small"
                  className="w-full"
                  type="password"
                  error={Boolean(error?.message)}
                  helperText={error?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="newPassword"
              control={control}
              rules={{
                required: { value: true, message: '入力必須項目です' },
                pattern: {
                  value: /.{8}/,
                  message: '8文字以上で入力してください',
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="新しいパスワード"
                  variant="standard"
                  size="small"
                  className="w-full"
                  type="password"
                  error={Boolean(error?.message)}
                  helperText={error?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="newPasswordConfirm"
              control={control}
              rules={{
                required: { value: true, message: '入力必須項目です' },
                pattern: {
                  value: /.{8}/,
                  message: '8文字以上で入力してください',
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="新しいパスワード(確認)"
                  variant="standard"
                  size="small"
                  className="w-full"
                  type="password"
                  error={Boolean(error?.message)}
                  helperText={error?.message}
                  {...field}
                />
              )}
            />

            {processing ? (
              <CircularProgress />
            ) : (
              <Button variant="contained" type="submit" disabled={disabledBtn}>
                {btnText}
              </Button>
            )}
          </form>
        </Paper>
      </Stack>
    </>
  );
};
