import { DialogOk } from '@common/components/Dialog';
import { userActions } from '@common/data/User';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Auth } from 'aws-amplify';
import { useEffect, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { parseNumberString } from '@common/utils/Formatter';

export const PhoneForm = ({ navigateUrl }: { navigateUrl?: string }) => {
  const user = userActions.useGetUser();
  const savePhone = userActions.useStorePhone();
  const saveUserFunc = userActions.useStoreUser();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [phone, setPhone] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const navigate = useNavigate();

  const { handleSubmit, control, reset, watch } = useForm<{ phone: string }>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      phone: user.phone,
    },
  });

  // formの値の監視
  const allWatch = watch();

  useEffect(() => {
    reset({
      phone: user.phone,
    });
  }, [user, reset]);

  useEffect(() => {
    if (allWatch.phone === user.phone) {
      setDisabledBtn(true);
      return;
    } else {
      setDisabledBtn(false);
    }
  }, [allWatch, user]);

  // NOTE: 携帯電話番号変更のコールバック
  const onSubmitPhone: SubmitHandler<{ phone: string }> = async (data) => {
    try {
      setProcessing(true);
      setPhone(data.phone);
      // cognitoの携帯電話番号を更新し、認証状態をfalseにする
      await savePhone(data.phone);
      await saveUserFunc({ ...user, phoneNumberVerified: false });
      // 認証コードを送信
      await Auth.verifyCurrentUserAttribute('phone_number');
      setProcessing(false);
      // 認証コード入力ダイアログを表示
      setOpenConfirm(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: Error | any) {
      setProcessing(false);
      if (error.code) {
        if (error.code === 'LimitExceededException') {
          setMessage('しばらく時間をおいてから再度お試しください');
        } else {
          setMessage('想定外のエラーです');
        }
      }
      setOpenError(true);

      console.error(error);
    }
  };

  // NOTE: 認証コード入力ダイアログのコールバック
  const onSubmitConfirm = async (code: string) => {
    try {
      setProcessing(true);
      await Auth.verifyCurrentUserAttributeSubmit('phone_number', code);
      // 認証が終わったらdynamoを更新
      await saveUserFunc({
        ...user,
        phone: phone,
        phoneNumberVerified: true,
      });

      if (navigateUrl) {
        // MEMO: React18のレンダリング制御により、下記のようにしないとnavigateが
        // 想定通りに動作しない
        setTimeout(() => {
          navigate(navigateUrl);
        }, 100);
        return;
      }

      setProcessing(false);
      setOpenConfirm(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: Error | any) {
      setProcessing(false);
      if (error.code) {
        if (error.code === 'ExpiredCodeException' || error.code === 'CodeMismatchException') {
          setMessage('認証コードが正しくありません');
        } else {
          setMessage('想定外のエラーです');
        }
      }
      setOpenError(true);

      console.error(error);
    }
  };

  return (
    <>
      <DialogOk
        open={openError}
        callbackOk={() => setOpenError(false)}
        title="エラー"
        message={message}
      />
      <ConfirmDialog
        open={openConfirm}
        processing={processing}
        phone={phone}
        onSubmit={onSubmitConfirm}
        callbackOnClose={() => {
          setOpenConfirm(false);
        }}
      />
      <Stack spacing={2} alignItems={'center'} className="p-5">
        <Typography variant="h5" className="text-center">
          {user.phoneNumberVerified ? '携帯電話番号の変更' : '携帯電話番号の認証'}
        </Typography>
        <Paper className="w-full flex flex-col items-center sm:w-[500px]">
          {user.phoneNumberVerified ? (
            <></>
          ) : (
            <p className="text-warning">ご利用には携帯電話番号の認証が必要です</p>
          )}
          <form
            onSubmit={handleSubmit(onSubmitPhone)}
            className="flex flex-col items-center w-4/5 mx-auto my-2"
          >
            <Controller
              name="phone"
              control={control}
              rules={{
                required: { value: true, message: '入力必須項目です' },
                pattern: {
                  value: /^0\d{1}0-{0,1}\d{4}-{0,1}\d{4}$/,
                  message: '有効な携帯電話番号ではありません',
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="携帯電話番号"
                  variant="standard"
                  size="small"
                  className="w-full"
                  type="tel"
                  error={Boolean(error?.message)}
                  helperText={error?.message}
                  {...field}
                />
              )}
            />
            {processing ? (
              <CircularProgress />
            ) : (
              <div className="mt-4">
                <Button
                  variant="contained"
                  type="submit"
                  disabled={disabledBtn && user.phoneNumberVerified}
                >
                  {user.phoneNumberVerified ? '変更' : '認証'}
                </Button>
              </div>
            )}
          </form>
        </Paper>
      </Stack>
    </>
  );
};

const ConfirmDialog = ({
  open,
  processing,
  phone,
  onSubmit,
  callbackOnClose,
}: {
  open: boolean;
  processing: boolean;
  phone: string;
  onSubmit: (code: string) => void;
  callbackOnClose: () => void;
}) => {
  const codeLength = 6; // 認証コードの桁数
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [code, setCode] = useState<string[]>(Array(codeLength).fill(''));

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number): void => {
    const value = parseNumberString(e.target.value, 'integer');

    setCode((prevCode) => {
      const newCode = [...prevCode];
      newCode[index] = value;
      return newCode;
    });

    if (value !== '' && index < codeLength - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number): void => {
    if (e.key === 'Backspace' && code[index] === '') {
      setCode((prevCode) => {
        const newCode = [...prevCode];
        newCode[index - 1] = '';
        return newCode;
      });

      if (index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>, index: number): void => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text/plain');
    const pastedCode = parseNumberString(pastedData.slice(0, codeLength - index), 'integer');

    setCode((prevCode) => {
      const newCode = [...prevCode];
      newCode.splice(index, pastedCode.length, ...pastedCode.split(''));
      return newCode;
    });

    if (index + pastedCode.length < codeLength) {
      inputRefs.current[index + pastedCode.length]?.focus();
    } else {
      inputRefs.current[codeLength - 1]?.focus();
    }
  };

  return (
    <Dialog open={open} onClose={callbackOnClose}>
      <div className="flex">
        <DialogTitle>認証コード入力</DialogTitle>
        <div className="ml-auto pt-1">
          <IconButton size="large" onClick={callbackOnClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <DialogContent>
        <p>「{phone}」にSMSを送信しました</p>
        <p>認証コードを入力してください</p>
        <div className="mt-4 flex justify-center gap-2">
          {code.map((char, index) => (
            <input
              key={index}
              type="text"
              inputMode="numeric"
              maxLength={1}
              value={char}
              onChange={(e) => handleInputChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onPaste={(e) => handlePaste(e, index)}
              ref={(ref) => (inputRefs.current[index] = ref)}
              className="w-10 h-10 border border-black rounded-md cursor-pointer text-center"
            />
          ))}
        </div>
        <div className="mt-4 flex justify-center">
          <Button
            variant="contained"
            disabled={processing}
            onClick={async () => {
              await onSubmit(code.join(''));
              setCode(Array(codeLength).fill(''));
            }}
          >
            確定
          </Button>
        </div>
        <p
          className="text-sm mt-4 cursor-pointer underline text-primary"
          onClick={() => {
            // 認証コードを送信
            Auth.verifyCurrentUserAttribute('phone_number');
          }}
        >
          認証コードを再送する
        </p>
      </DialogContent>
    </Dialog>
  );
};
