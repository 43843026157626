import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// MUI
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { dataOrder, OrderState, OrderItem } from '@common/data/Order';
import { dataShopItem } from '@common/data/ShopItem';

// 配達中の最初の注文詳細画面にリダイレクトする
// 複数ある場合は選択画面を表示する
export const Delivery = () => {
  const navigate = useNavigate();
  const orders = dataOrder.history.useOrderItemsOnDelivery();

  useEffect(() => {
    if (orders.length === 1) {
      navigate('/order/orders/' + orders[0].orderId, { replace: true });
    }
  }, [orders, navigate]);

  return (
    <div className="w-full p-4">
      {orders.length ? (
        <Stack spacing={2}>
          <h3 className="mb-4">配送状況を表示したい注文を選んでください。</h3>
          {orders
            .filter((order) => order.state !== 'paying')
            .map((order) => (
              <div
                key={order.orderId}
                onClick={() => navigate('/order/orders/' + order.orderId)}
                className="flex w-full h-24 items-center cursor-pointer rounded shadow shadow-gray-300"
              >
                <div className="font-bold p-8">
                  <ShowOrderState state={order.state} />
                </div>
                <div className="flex-1">
                  {order.timePaying.toLocaleString()}
                  <br />
                  {order.totalCount} 点のご注文
                </div>
                <OrderImage items={order.items} />
              </div>
            ))}
        </Stack>
      ) : (
        <Stack spacing={2} className="text-center pt-2">
          <div>表示する注文はありません。</div>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/')}
              className="w-80"
            >
              商品一覧へ
            </Button>
          </div>
        </Stack>
      )}
    </div>
  );
};

// 1番目の商品画像を表示する
// 複数の画像を表示するようにしたい
const OrderImage = ({ items }: { items: OrderItem[] }) => {
  const img = dataShopItem.itemMaster.useShopItemMaster(items[0]?.itemId)?.image[0];
  return <img alt="" src={img} className="object-cover h-full w-1/4 rounded" />;
};

const ShowOrderState = ({ state }: { state: OrderState }) => {
  switch (state) {
    case 'credited':
      return <span className="">受付待</span>;
    case 'assigned':
      return <span className="">出荷待</span>;
    case 'accepted':
      return <span className="">出荷待</span>;
    case 'onDelivery':
      return <Box sx={{ color: 'warning.main' }}>配達中</Box>;
    case 'arrived': // Box到着し、投函指示を受けたタイミングだが配達中にしておく
      return <Box sx={{ color: 'warning.main' }}>配達中</Box>;
    case 'posted':
      return <Box sx={{ color: 'warning.main' }}>受取待</Box>;
    case 'waitingCustomerByStockOut':
      return <Box sx={{ color: 'warning.main' }}>欠品連絡中</Box>;
    default:
      return <span></span>;
  }
};
