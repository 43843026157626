import { useEffect } from 'react';
import { Box } from '@mui/material';

import landing0Img from '@common/assets/landing-0.png';
import landing1Img from '@common/assets/landing-1.png';
import landing2Img from '@common/assets/landing-2.png';
import PDF_IMG from '@common/assets/sorakarabin-pdf.png';

export function ComingSoon() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box sx={{ width: '100%', p: 1, pb: 28, mb: -20 }}>
        <div className="text-center pt-2">
          <img
            src={landing0Img}
            alt=""
            className="responsive-img"
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              objectFit: 'cover',
              marginTop: '16px',
              marginBottom: '90px',
            }}
          />
          <img
            src={landing1Img}
            alt=""
            className="responsive-img"
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              objectFit: 'cover',
              marginTop: '16px',
              marginBottom: '150px',
            }}
          />
          <img
            src={landing2Img}
            alt=""
            className="responsive-img"
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              objectFit: 'cover',
              marginTop: '16px',
              marginBottom: '50px',
            }}
          />
        </div>
        <Box sx={{ textAlign: 'center', my: 1 }}>
          <span
            style={{
              display: 'block',
              color: 'black',
              fontSize: '25px',
              marginTop: '5px',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() => window.open(PDF_IMG, '_blank')}
          >
            開始日等の詳細はこちら
          </span>
        </Box>
      </Box>
    </>
  );
}
