/* eslint-disable no-irregular-whitespace */
export const SpecificTradeLawHtml = () => {
  return (
    <div>
      <p>特定商取引法に基づく表記</p>
      <p>販売業者名</p>
      <p>パナソニックホールディングス株式会社</p>
      <p></p>
      <p>販売責任者</p>
      <p> 小川 立夫</p>
      <p>所在地・連絡先</p>
      <p>所在地：〒571-8501&nbsp;大阪府門真市大字門真1006</p>
      <p>電話番号：070-7823-1009</p>
      <p>
        メールアドレス：
        <a href="mailto:sorakarabin@gg.jp.panasonic.com">sorakarabin@gg.jp.panasonic.com</a>
      </p>
      <p>受付時間：9:00-17:00(土日祝祭日・年末年始を除く)</p>
      <p>商品の販売価格</p>
      <p>
        各商品詳細ページ・購入確認画面ページにて税込価格で表示致します。
        <br />
        <br />
      </p>
      <p>商品の価格以外に発生する費用</p>
      <p>
        商品の価格以外に、ソラカラ便手数料(配送手数料、その他手数料)が発生する場合があります。詳細は、各商品詳細ページ・購入確認画面ページにて表示する金額をご確認ください。その他、ソラカラ便のページの閲覧、商品の購入、ソフトウェアのダウンロード等に必要となるインターネット接続料金、通信料金等はお客様の負担となります。
      </p>
      <p>代金の支払方法、支払時期</p>
      <p>支払方法は、各商品のご購入・決済ページにて選択された方法となります。</p>
      <p>
        下記の支払方法を選択いただけます。
        <br />
        <br />
      </p>
      <p>クレジットカード決済</p>
      <p>
        Visa、Master、JCB、AMEX、Dinersがご利用いただけます。代金の支払時期は、上記の支払方法を提供する会社とお客様との間の取り決めに従います。
      </p>
      <p>
        ・カード番号、有効期限、名義人等のクレジットカード情報は、ソフトバンク・ペイメント・サービス株式会社のシステムを通じて、カード発行会社に提供されます。弊社およびご利用店舗へこれらの情報が蓄積されることはございません。
      </p>
      <p>・クレジットカード情報はSSL/TLS通信にて暗号化されて送信しております。</p>
      <p>
        ・ご注文が正常完了できなかった以下の場合、当日配送の場合はご注文の翌日、ご予約の場合はお届け予定日の翌日又はトラブルが発生した翌日までに自動的にキャンセル処理を行います。
      </p>
      <p>・ご注文後にご注文の商品がご用意できなかった場合。</p>
      <p>・何らかのトラブルが発生し、ご注文が正常完了できなかった場合。</p>
      <p>キャッシュレス決済</p>
      <p>
        PayPayがご利用頂けます。代金の支払時期は、上記の支払方法を提供する会社とお客様との間の取り決めに従います。
      </p>
      <p> </p>
      <p>キャリア決済</p>
      <p>
        ソフトバンクまとめて支払い（B）、ドコモキャリア決済（d払い）がご利用いただけます。代金の支払時期は、上記の支払方法を提供する会社とお客様との間の取り決めに従います。
        <br />
        <br />
      </p>
      <p>商品の引き渡し時期</p>
      <p>
        配達予定時間は、配送状況確認ページにて表示しております。
        <br />
        ※表示する時間は、あくまでお届け予定時刻であるため実際の到着時間と前後する場合があります。予めご了承ください。
      </p>
      <p>返品・不良品について</p>
      <p>商品・サービスの性質上、お客様のご都合での返品はお断りいたします。</p>
      <p>
        商品に不良や品違いがございましたら、配送から1時間以内にご連絡下さい。不良や品違いが確認された場合に限り、返品または交換をお受けいたします。また、返品か交換かはお客様ご自身でお選び頂けますが、在庫の状況によってはお受けできない場合もございます。返品の場合は、商品代金およびソラカラ便手数料をお客様にお支払い頂いた方法で返金します。交換の場合は、当社が送料を負担します。なお、不良や品違いの商品は、当社が商品確認をするまでそのままお取り置き頂くか、該当商品の写真のご提供をお願いする場合がございます。商品のお取り置き、写真の提供が出来ない場合は、返品・交換の対象外とさせていただく場合がございます。
      </p>
      <p>キャンセル</p>
      <p>
        ご注文後、注文確定の表示がなされるまでは、お客様からキャンセルをすることができます。なお、注文確定後はお客様からキャンセルをすることができない場合がございます。詳しくは
        <a href="https://jppanasonic.sharepoint.com/:w:/r/sites/PJ853/Shared%20Documents/General/%E6%B3%95%E7%9A%84%E6%96%87%E6%9B%B8/220525%20%E5%88%A9%E7%94%A8%E8%A6%8F%E7%B4%84.docx?d=we6609102a6a846fcbe261f34db52d60b&amp;csf=1&amp;web=1&amp;e=oOaVnM">
          利用規約
        </a>
        の第7項をご覧下さい。
      </p>
      <p>お問い合わせ</p>
      <p>ソラカラ便お問合せ窓口</p>
      <p>住所：〒571-8501&nbsp;大阪府門真市大字門真1006 622-796&nbsp;</p>
      <p>
        メールアドレス：
        <a href="mailto:sorakarabin@gg.jp.panasonic.com">sorakarabin@gg.jp.panasonic.com</a>
      </p>
      <p>電話番号：070-7823-1009</p>
    </div>
  );
};
