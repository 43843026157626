import { useState, useEffect, useRef } from 'react';
import {
  GoogleMap,
  MarkerF,
  useJsApiLoader,
  // OverlayView,
  // OverlayViewF,
} from '@react-google-maps/api';
import Drone from '@common/assets/Drone.png';

import { CONST } from '@common/global';
import { OrderItemState } from '@common/data/OrderHistory';
import { Skeleton } from '@mui/material';
import { dataDeliveryTo } from '@common/data/OrderDeliveryTo';

import img_shop from '@common/assets/shop.svg';
import img_select_spot from '@common/assets/spot_select_128x128.png';

export const ShippingMap = ({ order }: { order: OrderItemState }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: CONST.GOOGLE_MAPS_APIKEY,
  });
  const spotAndShop = dataDeliveryTo.useSpotAndShopBySpotId(order.deliverySpotId);

  const mapRef = useRef<google.maps.Map | null>(null);
  const handleMapLoad = (map: google.maps.Map) => {
    mapRef.current = map;

    // LatLngBoundsを用い地図のビューポート（ズーム）を調整
    {
      const bounds = new google.maps.LatLngBounds();
      bounds.extend(positionSpot);
      bounds.extend(positionShop);
      map.fitBounds(bounds);
    }
  };

  const [zoomLevel, setZoomLevel] = useState<number>(17);
  const handleZoomChange = () => {
    if (mapRef.current) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const currentZoom = mapRef.current.getZoom()!;
      setZoomLevel(currentZoom);
    }
  };

  const latOffsetPerPixel = 2 / Math.pow(2, zoomLevel); //適当に算出
  const markerPixelOffset = 15; // 30ピクセルの半分
  const latOffset = markerPixelOffset * latOffsetPerPixel;

  // TODO: droneの位置を取得する方法が確立次第実装する
  const [positionDrone, setPositionDrone] = useState({
    lat: Number(spotAndShop.latitude),
    lng: Number(spotAndShop.longitude),
  });

  const positionSpot = {
    lat: spotAndShop.spot.latitude,
    lng: spotAndShop.spot.longitude,
  };
  const positionShop = {
    lat: Number(spotAndShop.latitude),
    lng: Number(spotAndShop.longitude),
  };

  useEffect(() => {
    if (order.state !== 'onDelivery' && order.state !== 'arrived') return;

    const interval = setInterval(() => {
      if (order.state === 'arrived') {
        setPositionDrone({ lat: positionSpot.lat, lng: positionSpot.lng });
        return;
      }

      const speed = 0.08;

      const deltaLat = speed * (positionSpot.lat - positionShop.lat);
      const deltaLng = speed * (positionSpot.lng - positionShop.lng);

      const newLat = positionDrone.lat + deltaLat;
      const newLng = positionDrone.lng + deltaLng;

      setPositionDrone({
        lat: newLat,
        lng: newLng,
      });

      if (
        Math.abs(newLat - positionSpot.lat) < Math.abs(deltaLat) &&
        Math.abs(newLng - positionSpot.lng) < Math.abs(deltaLng)
      ) {
        setPositionDrone(positionShop);
      }
    }, 500);

    return () => clearInterval(interval);
  }, [positionDrone, positionShop, positionSpot, order.state]);

  if (spotAndShop.shopId === 'error') {
    return <div>購入を行なった店舗が見つかりません。</div>;
  }
  if (spotAndShop.spot.spotId === 'error') {
    return <div>購入時に選択した配送先が見つかりません。</div>;
  }

  return isLoaded ? (
    <div style={{ position: 'relative' }}>
      <GoogleMap
        onLoad={handleMapLoad}
        onZoomChanged={handleZoomChange}
        mapContainerStyle={{
          height: '30vh',
          width: '100%',
        }}
        center={{
          lat: (Number(spotAndShop.latitude) + spotAndShop.spot.latitude) / 2,
          lng: (Number(spotAndShop.longitude) + spotAndShop.spot.longitude) / 2,
        }}
        zoom={17}
        options={{
          gestureHandling: 'none',
          styles: CONST.GOOGLE_MAPS_STYLES,
          mapTypeControl: false,
          streetViewControl: false,
          keyboardShortcuts: false,
          zoomControl: false,
          fullscreenControl: false, //全画面表示コントロール
        }}
      >
        {/* {isInfoWindowVisible && (
          <InfoWindowF
            position={{
              lat: Number(spotAndShop.latitude) + 0.0005,
              lng: Number(spotAndShop.longitude),
            }}
            onCloseClick={handleClose}
          >
            <div
              style={{
                background: 'white',
                fontSize: 10.5,
              }}
            >
              <h1>{spotAndShop.shopName}</h1>
            </div>
          </InfoWindowF>
        )}
        {isInfoWindowVisible && (
          <InfoWindowF
            position={{ lat: spotAndShop.spot.latitude + 0.0005, lng: spotAndShop.spot.longitude }}
            onCloseClick={handleClose}
          >
            <div
              style={{
                background: 'white',
                fontSize: 10.5,
              }}
            >
              <h1>{spotAndShop.spot.spotName}</h1>
            </div>
          </InfoWindowF>
        )} */}

        <MarkerF
          key={spotAndShop.latitude}
          position={positionShop}
          icon={{
            url: img_shop,
            scaledSize: new google.maps.Size(30, 30),
          }}
        />
        <MarkerF
          key={spotAndShop.spot.latitude}
          position={positionSpot}
          icon={{
            url: img_select_spot,
            scaledSize: new google.maps.Size(30, 30),
          }}
        />

        {(order.state === 'onDelivery' || order.state === 'arrived') && (
          <MarkerF
            key={Math.random()}
            position={{
              lat: order.state === 'arrived' ? positionDrone.lat + latOffset : positionDrone.lat,
              lng: positionDrone.lng,
            }}
            icon={{
              url: Drone,
              scaledSize: new google.maps.Size(40, 40),
            }}
          />
        )}

        {/* <OverlayView
          position={positionSpot}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          getPixelPositionOffset={getPixelPositionOffset}
        >
          <img src="https://via.placeholder.com/150" alt="overlay" />
        </OverlayView> */}
        {/* <GroundOverlay
          key={'url'}
          url="https://img.uxwing.com/wp-content/themes/uxwing/download/crime-security-military-law/air-drone-icon.svg"
          bounds={bounds}
          opacity={0.5}
        /> */}
        {/* <OverlayViewF
          position={position}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          getPixelPositionOffset={getPixelPositionOffset}
        >
          <img src={icon1} alt="overlay" />
        </OverlayViewF> */}
        {/* <PolylineF
          path={[positionShop, positionSpot]}
          options={{
            strokeOpacity: 0,
            icons: [
              {
                icon: {
                  path: 'M 0,-1 0,1',
                  strokeOpacity: 1,
                  scale: 4,
                  strokeColor: '#ff8c00',
                },
                offset: '0',
                repeat: '20px',
              },
            ],
          }}
        /> */}
        {/* <PolylineF
          path={[positionDrone, positionSpot]}
          options={{
            strokeColor: '#ff8c00',
            strokeOpacity: 1,
            strokeWeight: 2,
            icons: [
              {
                icon: {
                  path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                  scale: 3, // 矢印のサイズ
                  strokeColor: '#ff8c00', // 矢印の色
                },
                offset: '100%', // 矢印の位置
              },
            ],
          }}
        /> */}
      </GoogleMap>
      {/* Google規約等をクリックさせないためのダミーボタン */}
      <button
        style={{
          position: 'absolute',
          bottom: '0',
          right: '0',
          backgroundColor: 'transparent',
          border: 'none',
          padding: '10px',
          width: '130px',
          height: '10px',
        }}
      ></button>
      <button
        style={{
          position: 'absolute',
          bottom: '0',
          left: '0',
          backgroundColor: 'transparent',
          border: 'none',
          padding: '10px',
          width: '70px',
          height: '30px',
        }}
      ></button>
    </div>
  ) : (
    <Skeleton
      sx={{
        height: '30vh',
        width: '100%',
      }}
    />
  );
};
