import { Link } from 'react-router-dom';
// MUI
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export default function Cancel() {
  // ブラウザバックを無効化
  window.history.pushState(null, '', window.location.href);

  return (
    <main style={{ padding: '1rem 0' }}>
      <p className="pt-8 text-center">お支払いがキャンセルされました</p>
      <br />
      <br />
      <Box textAlign="center">
        <Link to="/">
          <Button variant="contained" autoFocus>
            Topページへ戻る
          </Button>
        </Link>
      </Box>
    </main>
  );
}
