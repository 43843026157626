export const parseNumberString = (value: string, type: 'integer' | 'minus'): string => {
  // 全角文字を半角に変換
  const transformedValue = value.replace(/[！-～]/g, (s) => {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });

  if (type === 'minus') {
    // 頭文字がマイナスでないパターンは数値のみに変換
    if (!value.match(/^-?[0-9]+$/)) {
      return transformedValue.replace(/[^0-9]/g, '');
    }
    return transformedValue.replace(/[^-0-9]/g, '');
  }

  return transformedValue.replace(/[^0-9]/g, ''); // 数値以外は削除
};

export const undefinedToEmptyString = (value: string | undefined): string => {
  return value ?? '';
};

export const undefinedToEmptyNumber = (value: number | undefined): number => {
  return value ?? 0;
};
