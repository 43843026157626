import { DialogOk } from '@common/components/Dialog';
import { Backdrop, Button, CircularProgress, Paper, TextField } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { dataShop } from '@common/data/Shop';

export const PreSignUp = () => {
  const navigate = useNavigate();
  const selectedShop = dataShop.useShopSelected();
  const [processing, setProcessing] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  console.log('-----------------------------');
  console.log(selectedShop);

  type FormType = {
    postalCode: string;
  };

  const { handleSubmit, control } = useForm<FormType>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      postalCode: '',
    },
  });

  // NOTE: 郵便番号のチェック
  const isAllowedPostalCode = (value: string) => {
    // ワイルドカードを正規表現に変換
    for (const code of selectedShop.allowablePostcodes) {
      const regexPattern = '^' + code.replace(/\*/g, '.*') + '$';
      const regex = new RegExp(regexPattern);
      if (regex.test(value)) {
        return true;
      }
    }
    return false;
  };

  const onSubmit: SubmitHandler<FormType> = async (data) => {
    setProcessing(true);
    try {
      if (isAllowedPostalCode(data.postalCode)) {
        // 登録可能な場合、新規会員登録ダイアログへ遷移
        navigate('/signUp');
      } else {
        setMessage(
          `申し訳ございません。
          この郵便番号は、サービス提供エリア外のため新規登録できません。
          エリア拡大までお待ち願います。`
        );
        setOpen(true);
      }
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div>
      <Backdrop open={processing} sx={{ zIndex: 1000 }}>
        <CircularProgress />
      </Backdrop>
      <DialogOk
        open={open}
        callbackOk={() => setOpen(false)}
        title="サービス提供エリア外エラー"
        message={message}
      ></DialogOk>

      <div className="w-full mt-4 flex justify-center items-center">
        <Paper className="w-[22rem] p-8" elevation={6}>
          <p className="font-semibold text-xl">サービス提供エリアの確認</p>
          <p className="mt-2 text-sm">
            サービス提供エリア確認のため、お住いの郵便番号を入力してください。
          </p>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col items-center gap-4 mx-auto mt-1"
          >
            <Controller
              name="postalCode"
              control={control}
              rules={{
                required: { value: true, message: '入力必須項目です' },
                pattern: {
                  value: /^\d{7}$/,
                  message: '有効な郵便番号ではありません',
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="郵便番号(ハイフンなし)"
                  variant="standard"
                  size="medium"
                  className="w-full"
                  error={Boolean(error?.message)}
                  helperText={error?.message}
                  {...field}
                />
              )}
            />
            <div className="mt-2">
              <Button variant="contained" type="submit">
                確認
              </Button>
            </div>
          </form>
          <p
            className="text-sm mt-2 cursor-pointer underline text-primary"
            onClick={() => navigate('/login')}
          >
            登録済みの方はこちら
          </p>
        </Paper>
      </div>
    </div>
  );
};
