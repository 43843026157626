import React, { Suspense, useEffect, useState } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  Navigate,
  Outlet,
  useLocation,
} from 'react-router-dom';
import { RecoilRoot } from 'recoil';

// MUI setup
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
// MUI
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

// MUI icon
// import RestoreIcon from '@mui/icons-material/Restore';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import ArchiveIcon from '@mui/icons-material/Archive';
// import StarRoundedIcon from '@mui/icons-material/StarRounded';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import AirplanemodeActiveRoundedIcon from '@mui/icons-material/AirplanemodeActiveRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
// import MenuIcon from '@mui/icons-material/Menu';
import StoreRoundedIcon from '@mui/icons-material/StoreRounded';

// Local components
// import logo from './logo-sorakarabin.svg';
import { CONST } from '@common/global';
import { ReactComponent as Logo } from '@common/assets/logo-sorakarabin.svg';
import { theme } from './theme';
import { Menu as MyMenu } from '../menu/Menu';
import { Shop } from '../shop/Shop';
import { ShopCategory } from '../shop/ShopCategory';
import { ShopItemDetails } from '../shop/ShopItemDetails';
import { Cart } from '../order/Cart';
import { SelectDeliveryTime } from '../order/SelectDeliveryTime';
import { OrderHistory } from '../order/OrderHistory';
import { Delivery } from '../order/Deliverry';
import { OrderDetails } from '../order/OrderDetails';
import Success from '../order/Success';
import Error from '../order/Error';
import Cancel from '../order/Cancel';
import { LPNijigaoka } from '../landing/nijigaoka';
import { LPIzukogen } from '../landing/izukogen';
import { ComingSoon } from '../landing/comingsoon';
import { UsageFlow } from '../landing/usageFlow';
// data
import { dataOrder } from '@common/data/Order';
import { dataShopItem } from '@common/data/ShopItem';
import { DataWantedRoute } from '@common/data/WantedRoute';
import { useDataInitialize } from '@common/hooks/UseDataInitialize';
import { useOnline } from '@common/hooks/UseOnline';
import { dataAppMessages } from '@common/data/AppMessages';

// Amplify
import { Amplify, Auth } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import awsExports from '../aws-exports';

//
import useLocationChange from '@common/hooks/UseLocationChange';
import { Tracking } from '@common//utils/Tracking';
import { UserInitialRegistration } from '../user/UserInitialRegistration';
import { UserInfo } from '../user/UserInfo';
import { TermsOfUse } from '../agreement/TermsOfUse';
import { PrivacyPolicy } from '../agreement/PrivacyPolicy';
import { SpecificTradeLaw } from '../agreement/SpecificTradeLaw';
import { userActions } from '@common/data/User';
import { agreementActions } from '@common/data/Agreement';
import { AddedCartItem } from '../order/AddedCartItem';
import { OrderConfirm } from '../order/OrderConfirm';
import { dataShopItemMaster } from '@common/data/ShopItemMaster';
import { Contact } from '../contact/Contact';
import { ShopSearchResult } from '../shop/ShopSearchResult';
import { Login } from '../login/Login';
import { PreSignUp } from '../login/PreSignUp';
import { SignUp } from '../login/SignUp';
import { ForgotPassword } from '../login/ForgotPassword';
import { ResetPassword } from '../login/ResetPassword';
import { Capacitor } from '@capacitor/core';
import { OrderQr } from '../order/OrderQr';
import { OrderProcess } from '../order/OrderProcess';
import { DialogSignOut } from '../menu/Menu';
import { SelectBox } from '../box/SelectBox';
import { PhoneInitialRegistration } from '../user/PhoneInitialRegistration';

Amplify.configure(awsExports);

export default function App() {
  // const setSignOut = dataUser.useSetSignOut();
  if (!process.env.REACT_APP_API_ENDPOINT) {
    console.error('REACT_APP_API_ENDPOINTが設定されていません.');
  }
  return (
    <ThemeProvider theme={theme}>
      <RecoilRoot>
        <BrowserRouter>
          <Suspense
            fallback={
              <div className="w-full h-screen flex justify-center items-center">
                <CircularProgress />
              </div>
            }
          >
            <App2 />
          </Suspense>
        </BrowserRouter>
      </RecoilRoot>
    </ThemeProvider>
  );
}

const isBeforeReleaseDate = () => {
  const releaseDate = new Date(CONST.PUBLIC_RELEASE_DATE);
  const now = new Date();
  return now < releaseDate;
};

function AuthenticatedRoute({ children }: { children?: JSX.Element }) {
  const isLogin = userActions.useIsLogin();
  if (!isLogin) {
    return <Navigate to="/login" replace />;
  }

  return <ProtectedRoute>{children ?? <Outlet />}</ProtectedRoute>;
}

// routeの制御
function ProtectedRoute({ children }: { children?: JSX.Element }) {
  const user = userActions.useGetUser();
  const termsOfUse = agreementActions.useFindAgreement('termsOfUse');
  const privacyPolicy = agreementActions.useFindAgreement('privacyPolicy');
  const location = useLocation();

  // 各種atomの取得を行なっておく
  useDataInitialize();

  // MEMO: 初期登録用のコンポーネントの表示時に同URLに遷移すると、
  // コンポーネントが表示されないことがあるため、そのままchildrenを返す
  const mustRegistrationUrlList = [
    '/user/phone',
    '/user/form',
    '/agreement/service/agree',
    '/agreement/policy/agree',
  ];
  if (mustRegistrationUrlList.some((url) => location.pathname.includes(url))) {
    return children ?? <Outlet />;
  }

  // MEMO: 規約のバージョンについて確定次第、条件の修正の必要あり
  if (user.termsOfUse !== termsOfUse?.version) {
    return <Navigate to="/agreement/service/agree" replace />;
  }

  if (user.privacyPolicy !== privacyPolicy?.version) {
    return <Navigate to="/agreement/policy/agree" replace />;
  }

  // MEMO: ユーザ情報では電話認証をfalseにし、ダイアログで変更を促すため遷移はしない
  if (!user.phoneNumberVerified && location.pathname !== '/user/info') {
    return <Navigate to="/user/phone" replace />;
  }

  if (!user.surName || !user.givenName) {
    return <Navigate to="/user/form" replace />;
  }

  return children ?? <Outlet />;
}
// eslint-disable-next-line complexity
function App2() {
  const [online, setOnline] = useState(navigator.onLine);
  const setWantedRoute = DataWantedRoute.useSetWantedRouteState();
  const isLogin = userActions.useIsLogin();

  // MEMO: オンライン状態変更時に走る処理
  useOnline(
    () => {
      setOnline(true);
    },
    () => {
      setOnline(false);
    }
  );
  const isVisibleFooterRoute = () => {
    // MEMO: 遷移先を保持しておきたくないURLのリスト
    // *で部分一致可能
    const notVisibleFooterUrlList = [
      '/login',
      '/preSignUp',
      '/signUp',
      '/forgotPassword',
      '/user/phone',
      '/user/form',
      '/agreement/service/agree',
      '/agreement/policy/agree',
      '/landing/*',
      '/resetPassword/*',
      '/usageFlow',
    ];
    const isPathMatching = (pattern: string) => {
      if (pattern.includes('*')) {
        const regexPattern = pattern.replace(/\*/g, '.*'); // * を .* に変換して正規表現にします
        const regex = new RegExp(`^${regexPattern}$`);
        return regex.test(location.pathname);
      }
      return pattern === location.pathname;
    };

    return !notVisibleFooterUrlList.some(isPathMatching);
  };

  const location = useLocation();
  useEffect(() => {
    if (isVisibleFooterRoute() && location.pathname !== '/') setWantedRoute(location.pathname);
  }, [location]);

  if (
    location.pathname !== '/login' &&
    location.pathname !== '/usageFlow' &&
    location.pathname !== '/067732500' &&
    location.pathname !== '/user/info'
  ) {
    if (isBeforeReleaseDate() && !isLogin) {
      return (
        <Box
          sx={{ py: 7, pt: Capacitor.getPlatform() === 'ios' ? 10 : 7 }}
          className="overflow-hidden"
        >
          <Header />
          <ComingSoon />
        </Box>
      );
    }
  }

  // py:7 は Header, MyBottomNavigation に隠れる部分
  return (
    <Box sx={{ py: 7, pt: Capacitor.getPlatform() === 'ios' ? 10 : 7 }} className="overflow-hidden">
      <Header />
      {/* <TopMessage /> */}
      {online ? (
        <Routes>
          <Route path="login" element={<Login />} />
          <Route path="preSignUp" element={<PreSignUp />} />
          <Route path="signUp" element={<SignUp />} />
          <Route path="067732500" element={<SignUp />} />
          <Route path="forgotPassword" element={<ForgotPassword />} />
          <Route path="resetPassword/:username" element={<ResetPassword />} />
          <Route index element={<Shop />} />
          <Route path="*" element={<Shop />} />
          <Route
            path="top"
            element={
              <AuthenticatedRoute>
                <Shop />
              </AuthenticatedRoute>
            }
          />
          <Route path="shop">
            <Route index element={<Shop />} />
            <Route path="categories/:categoryId" element={<ShopCategory />} />
            <Route path="items/:itemId" element={<ShopItemDetails />} />
            <Route path="search" element={<ShopSearchResult />} />
          </Route>
          <Route path="box">
            <Route index element={<SelectBox isOrdering={false} />} />
          </Route>
          <Route path="cart" element={<AuthenticatedRoute />}>
            <Route index element={<Cart />} />
            <Route path=":itemId/:count" element={<AddedCartItem />} />
          </Route>
          <Route path="order" element={<AuthenticatedRoute />}>
            <Route index element={<OrderHistory />} />
            <Route path="history" element={<OrderHistory />} />
            <Route path="on_delivery" element={<Delivery />} />
            <Route path="orders/:orderId" element={<OrderDetails />} />
            <Route path="qr/:orderId" element={<OrderQr />} />
            <Route path="process/:orderId" element={<OrderProcess />} />
            <Route path="select_box" element={<SelectBox isOrdering={true} />} />
            <Route path="select_time" element={<SelectDeliveryTime />} />
            <Route path="confirm" element={<OrderConfirm />} />
            <Route path="completed/:orderId" element={<Success />} />
            <Route path="failed/:orderId" element={<Error />} />
            <Route path="canceled/:orderId" element={<Cancel />} />
          </Route>
          <Route path="landing">
            <Route path="2150015" element={<LPNijigaoka />} />
            <Route path="izukogen" element={<LPIzukogen />} />
            <Route path="ComingSoon" element={<ComingSoon />} />
          </Route>
          <Route path="usageFlow" element={<UsageFlow />} />
          <Route
            path="menu"
            element={
              <AuthenticatedRoute>
                <MyMenu />
              </AuthenticatedRoute>
            }
          />
          <Route path="spot">
            <Route path=":spotId" element={<Shop />} />
          </Route>
          <Route path="user" element={<AuthenticatedRoute />}>
            <Route path="phone" element={<PhoneInitialRegistration />} />
            <Route path="form" element={<UserInitialRegistration />} />
            <Route path="info" element={<UserInfo />} />
          </Route>
          <Route path="agreement" element={<AuthenticatedRoute />}>
            <Route path="service" element={<TermsOfUse />}>
              <Route path="agree" element={<TermsOfUse />} />
            </Route>
            <Route path="policy" element={<PrivacyPolicy />}>
              <Route path="agree" element={<PrivacyPolicy />} />
            </Route>
            <Route path="specificTradeLaw" element={<SpecificTradeLaw />} />
          </Route>
          <Route path="contact" element={<Contact />} />
        </Routes>
      ) : (
        <div className="mt-2">
          <p className="text-center text-lg">通信中にエラーが発生しました。</p>
          <p className="text-center text-lg">通信環境をご確認の上、もう一度お試しください。</p>
        </div>
      )}
      {isVisibleFooterRoute() ? <MyBottomNavigation /> : <></>}
    </Box>
  );
}

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const isLogin = userActions.useIsLogin();
  const beforeReleaseDate = isBeforeReleaseDate();
  const [openSignOutDialog, setOpenSignOutDialog] = useState(false);

  if (process.env.REACT_APP_LOCAL_TEST !== '1') {
    const CheckServiceWorkerUpdate = () => {
      useLocationChange((location) => {
        // check for sw updates on page change
        navigator.serviceWorker
          ?.getRegistrations()
          .then((regs) => regs.forEach((reg) => reg.update()))
          .catch((error) => console.log('Error: Service worker is not registered.', error));
      });
      return <div>Sample Component</div>;
    };
    CheckServiceWorkerUpdate();
  }

  const canGoBack = () => location.key !== 'default';

  return (
    <>
      <AppBar position="fixed" color="inherit" elevation={2}>
        <Tracking />
        <Toolbar>
          <DialogSignOut
            open={openSignOutDialog}
            callbackOk={async () => {
              await Auth.signOut();
              navigate('/');
              navigate(0);
            }}
            callbackCancel={() => setOpenSignOutDialog(false)}
          />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ flexGrow: 1, pt: Capacitor.getPlatform() === 'ios' ? 5 : 0 }}
          >
            {canGoBack() && (
              <IconButton aria-label="back button" color="primary" onClick={() => navigate(-1)}>
                <ArrowBackIosNewRoundedIcon />
              </IconButton>
            )}
            <Box sx={{ color: '#2196f3', mx: 'auto' }}>
              <Logo fill="currentColor" className="h-10" />
            </Box>
            <Box />
            {isLogin || beforeReleaseDate ? null : (
              <IconButton aria-label="login" color="primary" onClick={() => navigate('/login')}>
                <LoginRoundedIcon fontSize="large" />
              </IconButton>
            )}
          </Stack>
        </Toolbar>
      </AppBar>
    </>
  );
}

// const TopMessage = () => {
//   const [show, setShow] = React.useState(true);
//   return show ? (
//     <div className="p-2 flex flex-row items-center text-primaryContrast bg-primary">
//       <div className="w-8 shrink-0">
//         <img alt="logo" src={logo7Now} />
//       </div>
//       <div className="grow px-2">セブンイレブンのネットコンビニ、7NOWが利用可能になりました。</div>
//       <div className="cursor-pointer" onClick={() => setShow(false)}>
//         <CloseRoundedIcon />
//       </div>
//     </div>
//   ) : (
//     <></>
//   );
// };

const MyBottomNavigation = () => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const cartTotalCount = dataOrder.cart.useCartTotalCount();

  const refreshStock = dataShopItem.stock.useShopItemStockRefresher();
  const updateSpotSelectedId = dataOrder.deliveryTo.useUpdateSpotSelectedId();
  const refreshCap = dataOrder.deliveryTime.useDeliveryTimeOptionRefresher();
  const refreshItems = dataShopItemMaster.useShopItemRefresher();
  const refreshAppMessages = dataAppMessages.useAppMessagesRefresher();

  // <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 100 }} elevation={3}>
  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          label="ストア"
          icon={<StoreRoundedIcon />}
          onClick={() => {
            refreshAppMessages();
            refreshStock();
            refreshCap();
            updateSpotSelectedId();
            refreshItems();
            navigate('/');
          }}
        />
        {/* <BottomNavigationAction label="お気に入り" icon={<StarRoundedIcon />}
          onClick={() => navigate("/favorite")} /> */}
        <BottomNavigationAction
          label="カート"
          icon={
            <Badge badgeContent={cartTotalCount} color="primary">
              <ShoppingCartRoundedIcon className="mx-1.5" />
            </Badge>
          }
          onClick={() => {
            refreshAppMessages();
            refreshStock();
            // refreshCap();
            refreshItems();
            navigate('/cart');
          }}
        />
        <BottomNavigationAction
          label="配送状況"
          icon={<AirplanemodeActiveRoundedIcon />}
          onClick={() => navigate('/order/on_delivery')}
        />
        <BottomNavigationAction
          label="メニュー"
          icon={<MenuRoundedIcon />}
          onClick={() => navigate('/menu')}
        />
      </BottomNavigation>
    </Paper>
  );
};
