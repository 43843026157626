import { selector, selectorFamily, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { api } from './Api';
import { RecoilKeys } from './RecoilKeys';

export type Agreement = {
  agreementName: string;
  version: string;
};

const agreementState = selector<Agreement[]>({
  key: RecoilKeys.AGREEMENT_STATE,
  get: () => api.fetchAgreements(),
});

const agreementByNameSelector = selectorFamily({
  key: RecoilKeys.AGREEMENT_FIND,
  get:
    (agreementName: 'termsOfUse' | 'privacyPolicy') =>
    ({ get }) =>
      get(agreementState).find((agreement) => agreement.agreementName === agreementName),
});

export const agreementActions = {
  useGetAgreements: () => useRecoilValue(agreementState),
  useGetAgreementsLoadable: () => useRecoilValueLoadable(agreementState),
  useFindAgreement: (agreementName: 'termsOfUse' | 'privacyPolicy') =>
    useRecoilValue(agreementByNameSelector(agreementName)),
};
