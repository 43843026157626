import { useState, useEffect } from 'react';
import { Button, Container, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { SpecificTradeLawHtml } from './SpecificTradeLawHtml';
import { userActions } from '@common/data/User';
import { CONST } from '@common/global';
import { agreementActions } from '@common/data/Agreement';

type Props = {
  title: string;
  fileName: string;
  agreeFunction: () => void;
};

export const Agreement = ({ title, fileName, agreeFunction }: Props) => {
  const user = userActions.useGetUser();
  const [html, setHtml] = useState('');
  const [isError, setIsError] = useState(false);
  const termsOfUse = agreementActions.useFindAgreement('termsOfUse');
  const privacyPolicy = agreementActions.useFindAgreement('privacyPolicy');

  // S3からhtmlファイルを取得する
  useEffect(() => {
    if (!CONST.CLOUDFRONT_URL) {
      console.error('CLOUDFRONT_URLがenvに設定されていません。');
      setIsError(true);
      return;
    }

    const src = CONST.CLOUDFRONT_URL + 'agreements/' + fileName + '.html';
    switch (fileName) {
      case 'TermsOfUse':
      case 'PrivacyPolicy':
        fetch(src)
          .then((res) => res.text())
          .then((text) => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(text, 'text/html');
            setHtml(doc.body.innerHTML);
          });
    }
  }, [fileName]);

  function HtmlData() {
    switch (fileName) {
      case 'TermsOfUse':
      case 'PrivacyPolicy':
        return (
          <Box
            dangerouslySetInnerHTML={{
              __html: html,
            }}
          ></Box>
        );
      // TODO: 特定商取引法は別のファイル・ページにしてもいいかも 利用規約・プライバシーポリシーと処理を同居している理由がない
      case 'SpecificTradeLaw':
        return <SpecificTradeLawHtml />;
      default:
        return <></>;
    }
  }

  const navigate = useNavigate();
  const signOut = async () => {
    await Auth.signOut();
    navigate('/');
    navigate(0);
  };

  const AgreeButton = () => {
    if (fileName === 'SpecificTradeLaw') return <></>;

    if (user.termsOfUse !== termsOfUse?.version || user.privacyPolicy !== privacyPolicy?.version) {
      return (
        <Box
          className="p-4 text-center"
          sx={{
            width: '100vw',
            backgroundColor: 'white',
            position: 'fixed',
            bottom: 0,
            'z-index': 9999,
          }}
        >
          <Button variant="contained" color="primary" className="w-80" onClick={agreeFunction}>
            同意する
          </Button>
          <Button onClick={signOut}>ログアウト</Button>
        </Box>
      );
    } else {
      return (
        <Box
          className="p-4 text-center"
          sx={{
            width: '100vw',
            backgroundColor: 'white',
            position: 'fixed',
            bottom: 0,
            'z-index': 9999,
          }}
        >
          <Button variant="contained" color="primary" className="w-80" onClick={() => navigate(-1)}>
            同意済み
          </Button>
        </Box>
      );
    }
  };

  return (
    <>
      <Container maxWidth="lg" className="py-4 mb-10">
        <Box className="mx-auto">
          <Typography variant="h5" className="text-center">
            {title}
          </Typography>
          <Typography component={'div'} className="pt-2">
            {isError ? '規約の取得に失敗しました。' : <HtmlData />}
          </Typography>
        </Box>
      </Container>
      <AgreeButton />
    </>
  );
};
