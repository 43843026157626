// react-router v6だと、useHistoryと互換するインターフェイスが存在しない
// import { useHistory } from 'react-router-dom';
import useLocationChange from '../hooks/UseLocationChange';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gtag: any;
  }
}

export const Tracking = () => {
  useLocationChange((location) => {
    console.log(location.pathname);
    window.gtag('config', 'GTM-NZ2L2GZ', { page_path: location.pathname });
  });

  return <div></div>;
};

// declare global {
//   interface Window {
//     gtag?: (key: string, trackingId: string, config: { page_path: string }) => void;
//   }
// }

// export const useTracking = (trackingId: string | undefined = process.env.GA_MEASUREMENT_ID) => {
//   const { listen } = useHistory();

//   useEffect(() => {
//     const unlisten = listen((location) => {
//       if (!window.gtag) return;
//       if (!trackingId) {
//         console.log(
//           'Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`.',
//         );
//         return;
//       }
//       window.gtag('config', trackingId, { page_path: location.pathname });
//     });

//     return unlisten;
//   }, [trackingId, listen]);
// };
