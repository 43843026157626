import { useNavigate, useParams } from 'react-router-dom';

// MUI
import Box from '@mui/material/Box';
// MUI Icon

import { Price } from '@common/components/Price';
import { dataOrder } from '@common/data/Order';
import { dataShopItem } from '@common/data/ShopItem';
import { dataShopItemMaster } from '@common/data/ShopItemMaster';

export function AddedCartItem() {
  const navigate = useNavigate();
  const { itemId } = useParams();
  const { count } = useParams();
  const item = itemId ? dataShopItem.itemMaster.useShopItemMaster(itemId) : undefined;

  const refreshStock = dataShopItem.stock.useShopItemStockRefresher();
  const refreshCap = dataOrder.deliveryTime.useDeliveryTimeOptionRefresher();
  const refreshItems = dataShopItemMaster.useShopItemRefresher();
  const availableByCartSize = itemId ? dataOrder.cart.useItemAvailableCountByCartSize(itemId) : 0;
  const availableByCartWeight = itemId
    ? dataOrder.cart.useItemAvailableCountByCartWeight(itemId)
    : 0;

  return (
    <Box sx={{ width: '100%', p: 1, pb: 8 }}>
      <div>
        <div className="mb-2 flex flex-col w-full h-14">
          <span>以下の商品をカートに{count ?? 0}個追加しました。</span>
          <span>個数の変更は注文時に行うことができます。</span>
        </div>
        {item ? (
          <div
            key={item.itemId}
            className="flex flex-row mb-2 w-full h-28 items-center rounded-md shadow"
          >
            <div
              className="w-2/4 h-full cursor-pointer"
              onClick={() => navigate('/shop/items/' + item.itemId)}
            >
              <img
                alt="商品画像"
                src={item.image[0]}
                className="w-full h-full object-cover rounded-l-md"
              />
            </div>
            <div className="pl-4 pt-2 pb-0 h-full grow flex flex-col relative">
              <div className="grow text-xs line-clamp-2">{item.makerName}</div>
              <div className="grow text-base line-clamp-2">{item.name}</div>
              <div className="text-right">
                <Price price={item.price} />
                <span className="text-lg font-bold"> {count}</span>
                <span className="text-xs"> 個</span>
              </div>
            </div>
          </div>
        ) : (
          <div> 商品が見つかりませんでした。</div>
        )}
      </div>
      <div
        className="fixed bottom-0 left-0 right-0 p-2 pb-20 ssm:pb-16 flex flex-col items-center 
        text-primaryContrast"
      >
        {availableByCartSize > 0 && availableByCartWeight > 0 && (
          <div
            className="w-full py-3 my-1 mb-3 rounded-full cursor-pointer 
        text-center text-2xl font-bold text-primary border-primary border-2"
            onClick={() => {
              refreshStock();
              refreshCap();
              refreshItems();
              navigate('/shop');
            }}
          >
            買い物を続ける
          </div>
        )}
        <div
          className="w-full py-3 rounded-full cursor-pointer 
        text-center text-2xl font-bold text-white bg-primary"
          onClick={() => {
            refreshStock();
            refreshCap();
            refreshItems();
            navigate('/cart');
          }}
        >
          ご注文へ進む
        </div>
      </div>
    </Box>
  );
}
