import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

// MUI
import Box from '@mui/material/Box';
import landing0Img from '@common/assets/landing-0.png';
import landing1Img from '@common/assets/landing-1.png';
import landing2Img from '@common/assets/landing-2.png';
import { dataDeliveryTo } from '@common/data/OrderDeliveryTo';
import { dataShopItemMaster } from '@common/data/ShopItemMaster';
import { dataAppMessages } from '@common/data/AppMessages';

export function LPNijigaoka() {
  const navigate = useNavigate();
  const [, setSpot] = dataDeliveryTo.useSpotSelectedIdState();
  const refreshItems = dataShopItemMaster.useShopItemRefresher();
  const refreshAppMessages = dataAppMessages.useAppMessagesRefresher();

  const spotId = '2';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box sx={{ width: '100%', p: 1, pb: 28, mb: -10 }}>
        <div className="text-center pt-2">
          <img
            src={landing0Img}
            alt=""
            className="responsive-img"
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              objectFit: 'cover',
              marginTop: '16px',
              marginBottom: '90px',
            }}
          />
          <img
            src={landing1Img}
            alt=""
            className="responsive-img"
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              objectFit: 'cover',
              marginTop: '16px',
              marginBottom: '150px',
            }}
          />
          <img
            src={landing2Img}
            alt=""
            className="responsive-img"
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              objectFit: 'cover',
              marginTop: '16px',
              marginBottom: '50px',
            }}
          />
        </div>
        <Box sx={{ textAlign: 'center', my: 1 }}>
          <span
            style={{
              textDecoration: 'underline',
              cursor: 'pointer',
              color: 'black',
              fontSize: '25px', // 文字サイズを大きく設定
            }}
            onClick={() => navigate('/usageFlow')}
          >
            ご利用の流れを確認する
          </span>
        </Box>
      </Box>

      <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, p: 1 }}>
        <div
          className="w-full py-3 mb-2 rounded-full cursor-pointer text-center text-2xl font-bold text-white bg-primary"
          onClick={() => {
            setSpot(spotId);
            refreshItems();
            refreshAppMessages();
            navigate('/login');
          }}
        >
          会員登録／ログイン
        </div>
        <div
          className="w-full py-3 rounded-full cursor-pointer text-center text-2xl font-bold text-white bg-primary"
          onClick={() => navigate('/spot/' + spotId)}
        >
          商品を見る
        </div>
      </Box>
    </>
  );
}
