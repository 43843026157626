import { Link, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useFetchFailedMessage } from '@common/data/ApiOrder';

export default function Error() {
  // ブラウザバックを無効化
  window.history.pushState(null, '', window.location.href);

  const { orderId } = useParams();
  const failedMessage = useFetchFailedMessage(orderId ?? '');

  return (
    <main style={{ padding: '1rem 0' }}>
      <p className="pt-8 text-center">お支払いに失敗しました</p>
      <p className="pt-8 text-center whitespace-pre-wrap">{failedMessage}</p>
      <br />
      <br />
      <Box textAlign="center">
        <Link to="/">
          <Button variant="contained" autoFocus>
            Topページへ戻る
          </Button>
        </Link>
      </Box>
    </main>
  );
}
