import React from 'react';
import { useNavigate } from 'react-router-dom';

// MUI
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
// MUI Icon
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import ClearIcon from '@mui/icons-material/Clear';

import { Price } from '@common/components/Price';
import { dataOrder } from '@common/data/Order';
import { dataShop } from '@common/data/Shop';
import { DialogOkCancel } from '@common/components/Dialog';
import { Divider, Typography } from '@mui/material';
import { OrderStepper } from './OrderStepper';

export function Cart() {
  const [openRemoveSelectedCartItemDialog, setOpenRemoveSelectedCartItemDialog] =
    React.useState(false);
  type selectedItem = {
    itemId: string;
    itemName: string;
  };
  const [selectedCartItem, setSelectedCartItem] = React.useState<selectedItem>({
    itemId: '',
    itemName: '',
  });
  const navigate = useNavigate();
  const cartItems = dataOrder.cart.useCartItems();
  console.log(cartItems);

  const cartTotalCount = dataOrder.cart.useCartTotalCount();
  const addableByStock = dataOrder.cart.useCartItemsAddableCountByStock();
  const addableBySize = dataOrder.cart.useIsCartItemsAddableBySize();
  const addableByWeight = dataOrder.cart.useIsCartItemsAddableByWeight();
  const isAddableByCount = dataOrder.cart.useIsCartItemsAddableByCount();
  const incrementCartItem = dataOrder.cart.useIncrementCartItem();
  const decrementCartItem = dataOrder.cart.useDecrementCartItem();
  const removeCartItem = dataOrder.cart.useRemoveCartItem();

  const removeSelectedCartItem = (itemId: string) => {
    removeCartItem(itemId);
    setOpenRemoveSelectedCartItemDialog(false);
  };

  return (
    <Box className="w-full p-1 mb-16 ssm:mb-12">
      {cartTotalCount ? (
        <>
          <OrderStepper activeStep={0} />
          <Typography className="py-4">カートには以下の商品が入っています。</Typography>
          <div>
            {cartItems.map((item, index) => (
              <div
                key={item.itemId}
                className="flex flex-row mb-2 w-full h-28 items-center rounded-md shadow"
              >
                <DialogOkCancel
                  open={openRemoveSelectedCartItemDialog}
                  title={''}
                  message={selectedCartItem.itemName + 'をカートから削除しますか？'}
                  callbackOk={() => removeSelectedCartItem(selectedCartItem.itemId)}
                  callbackCancel={() => setOpenRemoveSelectedCartItemDialog(false)}
                />
                <div
                  className="w-1/4 h-full cursor-pointer"
                  onClick={() => navigate('/shop/items/' + item.itemId)}
                >
                  <img
                    alt="商品画像"
                    src={item.image[0]}
                    className="w-full h-full object-cover rounded-l-md"
                  />
                </div>
                <div className="pl-4 pt-2 pb-0 h-full grow flex flex-col relative">
                  <div className="grow flex  flex-row ">
                    <div className="grow text-base line-clamp-2"> {item.name} </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        setSelectedCartItem({ itemId: item.itemId, itemName: item.name });
                        setOpenRemoveSelectedCartItemDialog(true);
                      }}
                    >
                      <ClearIcon />
                    </div>
                  </div>
                  <div className="text-right">
                    <Price price={item.price} />
                    <IconButton
                      color="primary"
                      onClick={() => decrementCartItem(item.itemId)}
                      disabled={item.count === 1}
                    >
                      <RemoveCircleRoundedIcon />
                    </IconButton>
                    <span className="text-lg font-bold">{item.count}</span>
                    <span className="text-xs"> 個</span>
                    <IconButton
                      color="primary"
                      onClick={() => incrementCartItem(item.itemId)}
                      disabled={
                        !(
                          addableByStock[index] > 0 &&
                          addableBySize[index] &&
                          addableByWeight[index] &&
                          isAddableByCount
                        )
                      }
                    >
                      <AddCircleRoundedIcon />
                    </IconButton>
                  </div>
                  <React.Suspense fallback={<></>}>
                    <ItemMessage index={index} itemId={item.itemId} itemCount={item.count} />
                  </React.Suspense>
                </div>
              </div>
            ))}
          </div>
          <CartPrice />
          <PurchasePanel />
        </>
      ) : (
        <Stack spacing={2} className="text-center pt-2">
          <div>カートに商品が入っていません。</div>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/')}
              className="w-80"
            >
              商品一覧へ
            </Button>
          </div>
        </Stack>
      )}
    </Box>
  );
}

export const CartPrice = () => {
  const cartTotalCount = dataOrder.cart.useCartTotalCount();
  const cartPrice = dataOrder.cart.useCartPrice();
  const deliveryFee = dataOrder.deliveryFee.useDeliveryFee();
  const discount = dataOrder.discount.useDiscount();
  return (
    <div className="p-4">
      {discount < 0 && (
        <Box className="flex mx-auto" sx={{ color: 'red', fontWeight: 'bold' }}>
          <span>＼</span>
          <span className="mx-4">送料無料キャンペーン適用</span>
          <span>／</span>
        </Box>
      )}
      {deliveryFee === 0 && (
        <Box className="flex mx-auto" sx={{ color: 'red', fontWeight: 'bold' }}>
          <span>＼</span>
          <span className="mx-4">送料無料キャンペーン中</span>
          <span>／</span>
        </Box>
      )}
      <Box className="flex">
        商品合計 ({cartTotalCount}点) :
        <Box className="ml-auto">
          {cartPrice.toLocaleString()}
          <span className="text-xs"> 円(税込)</span>
        </Box>
      </Box>
      <Box className="flex">
        送料:
        <Box className="ml-auto">
          {deliveryFee.toLocaleString()}
          <span className="text-xs"> 円(税込)</span>
        </Box>
      </Box>
      {discount < 0 && (
        <Box className="flex">
          割引:
          <Box className="ml-auto" sx={{ color: 'red', fontWeight: 'bold' }}>
            {discount.toLocaleString()}
            <span className="text-xs"> 円(税込)</span>
          </Box>
        </Box>
      )}
      <Divider />
      <Box className="flex">
        お支払い合計金額：
        <Box className="ml-auto">
          <Price price={cartPrice + deliveryFee + discount} />
        </Box>
      </Box>
    </div>
  );
};

export const ItemMessage = ({
  index,
  itemId,
  itemCount,
}: {
  index: number;
  itemId: string;
  itemCount: number;
}) => {
  const removeCartItem = dataOrder.cart.useRemoveCartItem();
  const availableByTodaysLunchDeadline =
    dataOrder.cart.useIsCartItemsAvailableByTodaysLunchDeadline();
  const addableByStock = dataOrder.cart.useCartItemsAddableCountByStock();
  const addableBySize = dataOrder.cart.useIsCartItemsAddableBySize();
  const addableByWeight = dataOrder.cart.useIsCartItemsAddableByWeight();
  const isAddableByCount = dataOrder.cart.useIsCartItemsAddableByCount();

  return availableByTodaysLunchDeadline[index] === false ? (
    <div className="absolute bottom-1 right-1 flex flex-col bg-white">
      <div className="text-warning text-xs">
        この商品の当日のご注文は午前11時までとさせていただいております。
      </div>
      <div className="flex flex-row justify-end">
        <div
          className="w-fit h-fit mr-1 mt-1 px-3 py-1 rounded-md cursor-pointer 
            text-center text-md text-warning border-2 border-warning"
          onClick={() => removeCartItem(itemId)}
        >
          カートから削除する
        </div>
      </div>
    </div>
  ) : addableByStock[index] < 0 ? (
    <div className="relative bottom-6 right-1 text-right">
      <span
        className="absolute bottom-4 right-0 px-2 py-1 rounded
            text-warningContrast bg-warning text-xs"
      >
        残り在庫数が {addableByStock[index] + itemCount} です
        <br />
        購入数を減らしてください
      </span>
      <span
        className="absolute bottom-0 right-2 
            w-0 h-0 border-8 border-transparent border-t-warning"
      />
    </div>
  ) : addableByStock[index] === 0 ? (
    <div className="relative bottom-6 right-1 text-right">
      <span
        className="absolute bottom-4 right-0 px-2 py-1 rounded
            text-white bg-gray-400 text-xs"
      >
        在庫上限です
      </span>
      <span
        className="absolute bottom-0 right-2 
            w-0 h-0 border-8 border-transparent border-t-gray-400"
      />
    </div>
  ) : addableBySize[index] === false ? (
    <div className="relative bottom-6 right-1 text-right">
      <span
        className="absolute bottom-4 right-0 px-2 py-1 rounded
            text-white bg-gray-400 text-xs"
      >
        配達容量が上限に達しています
      </span>
      <span
        className="absolute bottom-0 right-2 
            w-0 h-0 border-8 border-transparent border-t-gray-400"
      />
    </div>
  ) : addableByWeight[index] === false ? (
    <div className="relative bottom-6 right-1 text-right">
      <span
        className="absolute bottom-2 right-0 px-2 py-1 rounded
        text-white bg-gray-400 text-xs"
      >
        配達重量が上限に達しています
      </span>
      <span
        className="absolute bottom-0 right-2 
        w-0 h-0 border-8 border-transparent border-t-gray-400"
      />
    </div>
  ) : isAddableByCount === false ? (
    <div className="relative bottom-6 right-1 text-right">
      <span
        className="absolute bottom-2 right-0 px-2 py-1 rounded
        text-white bg-gray-400 text-xs"
      >
        配達総数が上限に達しています
      </span>
      <span
        className="absolute bottom-0 right-2
        w-0 h-0 border-8 border-transparent border-t-gray-400"
      />
    </div>
  ) : (
    <div></div>
  );
};

function PurchasePanel() {
  const cartReady = dataOrder.cart.useIsCartReadyToBuy();
  const hasTwoOrMoreSpots = dataShop.useHasTwoOrMoreSpots();
  const navigate = useNavigate();

  const nextCaption = hasTwoOrMoreSpots ? 'お届け先の選択へ' : 'お届け先の確認へ';

  // pb:7 は MyBottomNavigation に隠れる高さ
  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        p: 1,
        pb: 8,
      }}
    >
      <Stack direction="column" spacing={1}>
        {cartReady ? (
          <Box
            className="w-full py-3 rounded-full cursor-pointer 
                text-center text-2xl font-bold text-white bg-primary"
            onClick={() => navigate('/order/select_box')}
          >
            {nextCaption}
          </Box>
        ) : (
          <Box
            className="w-full py-3 rounded-full cursor-pointer 
                text-center text-2xl font-bold text-warningContrast bg-warning"
          >
            カートの内容をご確認ください
          </Box>
        )}
      </Stack>
    </Paper>
  );
}
