import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { userActions } from '@common/data/User';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { DialogOk } from '@common/components/Dialog';

type FormType = {
  surName: string;
  givenName: string;
  gender: string;
  age: string;
  postalCode: string;
  address: string;
  // email: string;
  rejectEmail: boolean;
};

type Props = {
  title: string;
  btnText: string;
  navigateUrl?: string;
};

export const UserForm = ({ title, btnText, navigateUrl }: Props) => {
  const user = userActions.useGetUser();
  const saveUserFunc = userActions.useStoreUser();
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [open, setOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();
  const { handleSubmit, control, reset, watch } = useForm<FormType>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      surName: '',
      givenName: '',
      gender: '',
      age: '',
      postalCode: '',
      address: '',
      // email: '',
      rejectEmail: false,
    },
  });
  // formの値の監視
  const allWatch = watch();

  useEffect(() => {
    reset({
      surName: user.surName,
      givenName: user.givenName,
      gender: user.gender,
      age: user.age,
      postalCode: user.postalCode,
      address: user.address,
      // email: user.email,
      rejectEmail: user.rejectEmail || false,
    });
  }, [user, reset]);

  useEffect(() => {
    if (
      allWatch.surName === user.surName &&
      allWatch.givenName === user.givenName &&
      // allWatch.email === user.email &&
      allWatch.gender === user.gender &&
      allWatch.age === user.age &&
      allWatch.postalCode === user.postalCode &&
      allWatch.address === user.address &&
      allWatch.rejectEmail === user.rejectEmail
    ) {
      setDisabledBtn(true);
      return;
    } else {
      setDisabledBtn(false);
    }
  }, [allWatch, user]);

  const onSubmit: SubmitHandler<FormType> = async (data) => {
    if (processing) return;

    setProcessing(true);
    await saveUserFunc({ ...user, ...data });
    setProcessing(false);

    if (navigateUrl) {
      // MEMO: React18のレンダリング制御により、下記のようにしないとnavigateが
      // 想定通りに動作しない
      setTimeout(() => {
        navigate(navigateUrl);
      });
      return;
    }

    setOpen(true);
  };

  return (
    <>
      <DialogOk
        open={open}
        message="変更を登録しました"
        callbackOk={() => setOpen(false)}
        callbackOnClose={() => setOpen(false)}
      />
      <Stack spacing={2} alignItems={'center'} className="p-5">
        <Typography variant="h5" className="text-center">
          {title}
        </Typography>
        <Paper className="w-full sm:w-[500px]">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-4 w-4/5 mx-auto my-2"
          >
            <Controller
              name="surName"
              control={control}
              rules={{ required: { value: true, message: '入力必須項目です' } }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="姓"
                  variant="standard"
                  size="small"
                  className="w-full"
                  error={Boolean(error?.message)}
                  helperText={error?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="givenName"
              control={control}
              rules={{ required: { value: true, message: '入力必須項目です' } }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="名"
                  variant="standard"
                  size="small"
                  className="w-full"
                  error={Boolean(error?.message)}
                  helperText={error?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="gender"
              control={control}
              rules={{
                required: { value: true, message: '入力必須項目です' },
              }}
              render={({ field, fieldState: { error } }) => (
                <FormControl error={Boolean(error?.message)}>
                  <FormLabel style={{ fontSize: '0.75rem' }}>性別</FormLabel>
                  <RadioGroup aria-label="性別" row sx={{ justifyContent: 'center' }} {...field}>
                    <FormControlLabel value="男性" control={<Radio />} label="男性" />
                    <FormControlLabel value="女性" control={<Radio />} label="女性" />
                    <FormControlLabel value="その他" control={<Radio />} label="その他" />
                  </RadioGroup>
                  <FormHelperText>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
            <Controller
              name="age"
              control={control}
              rules={{
                required: { value: true, message: '入力必須項目です' },
              }}
              render={({ field, fieldState: { error } }) => (
                <FormControl className="w-1/2" error={Boolean(error?.message)}>
                  <InputLabel>年代</InputLabel>
                  <Select label="年代" {...field}>
                    <MenuItem value="10代">10〜19</MenuItem>
                    <MenuItem value="20代">20〜29</MenuItem>
                    <MenuItem value="30代">30〜39</MenuItem>
                    <MenuItem value="40代">40〜49</MenuItem>
                    <MenuItem value="50代">50〜59</MenuItem>
                    <MenuItem value="60代">60〜69</MenuItem>
                    <MenuItem value="70代">70〜79</MenuItem>
                    <MenuItem value="80代">80〜89</MenuItem>
                    <MenuItem value="90代">90〜99</MenuItem>
                  </Select>
                  <FormHelperText>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
            <Controller
              name="postalCode"
              control={control}
              rules={{
                required: { value: true, message: '入力必須項目です' },
                pattern: {
                  value: /^\d{7}$/,
                  message: '有効な郵便番号ではありません',
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="郵便番号(ハイフンなし)"
                  variant="standard"
                  size="small"
                  className="w-full"
                  InputProps={{
                    startAdornment: <span className="mr-1">〒</span>,
                  }}
                  error={Boolean(error?.message)}
                  helperText={error?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="address"
              control={control}
              rules={{
                required: { value: true, message: '入力必須項目です' },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="住所"
                  variant="standard"
                  size="small"
                  className="w-full"
                  error={Boolean(error?.message)}
                  helperText={error?.message}
                  {...field}
                />
              )}
            />

            <Typography variant="caption" color="textSecondary" className="my-1">
              メール配信設定
            </Typography>
            <Controller
              name="rejectEmail"
              control={control}
              rules={{
                required: false,
              }}
              render={({ field }) => (
                <FormControl style={{ marginTop: -15 }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          // field.valueは反転して利用
                          checked={!field.value}
                          onChange={(e) => field.onChange(!e.target.checked)}
                        />
                      }
                      label="実証実験の開催日などのお知らせのメール配信を受け取る"
                    />
                  </FormGroup>
                </FormControl>
              )}
            />
            {/* MEMO: 不正なメールアドレスだとログインできなくなるので一旦コメントアウト */}
            {/* <Controller
              name="email"
              control={control}
              rules={{
                required: { value: true, message: '入力必須項目です' },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="メールアドレス"
                  variant="standard"
                  size="small"
                  className="w-full"
                  type="tel"
                  error={Boolean(error?.message)}
                  helperText={error?.message}
                  {...field}
                />
              )}
            /> */}
            {processing ? (
              <CircularProgress />
            ) : (
              <div className="flex justify-center">
                <Button variant="contained" type="submit" disabled={disabledBtn}>
                  {btnText}
                </Button>
              </div>
            )}
          </form>
        </Paper>
      </Stack>
    </>
  );
};
