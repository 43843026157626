// import {useState} from 'react';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
// MUI
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { CONST } from '@common/global';
import { DialogOk, DialogOkCancel } from '@common/components/Dialog';
import { Price } from '@common/components/Price';
import { dataOrder, DeliveryTime, OrderItemState } from '@common/data/Order';
import { dataShopItem } from '@common/data/ShopItem';
import { ShippingMap } from './ShippingMap';
import { api } from '@common/data/Api';

import { Banner } from './Banner';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  StepConnector,
  StepIconProps,
  stepConnectorClasses,
  styled,
} from '@mui/material';
import { ReactComponent as Drone } from '@common/assets/Drone.svg';
import { useJudgeSize } from '@common/utils/JudgeSize';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import moment from 'moment';

export const OrderDetails = () => {
  return (
    <React.Suspense
      fallback={
        <div>
          <div className="text-center pt-2">ご注文を確認しています</div>
          <div className="w-full h-screen flex justify-center items-center">
            <CircularProgress />
          </div>
        </div>
      }
    >
      <OrderDetails2 />
    </React.Suspense>
  );
};

const DeliveryTimeChangeBtn = ({ order }: { order: OrderItemState }) => {
  const [open, setOpen] = useState(false);
  const deliveryTimeRefresh = dataOrder.deliveryTime.useDeliveryTimeOptionRefresher();
  const closeCallback = () => setOpen(false);
  return (
    <>
      {order.deliveryTime && (
        <DeliveryTimeChangeDialog
          orderId={order.orderId}
          spotId={order.deliverySpotId}
          deliveryTime={order.deliveryTime}
          open={open}
          closeCallback={closeCallback}
        />
      )}
      <div
        className="w-full mt-4 py-3 rounded-full cursor-pointer 
                  text-center text-xl font-bold text-primary border-primary border-2"
        onClick={() => {
          deliveryTimeRefresh();
          setOpen(true);
        }}
      >
        配達時間を変更する
      </div>
    </>
  );
};

const DeliveryTimeChangeDialog = ({
  orderId,
  spotId,
  deliveryTime,
  open,
  closeCallback,
}: {
  orderId: string;
  spotId: string;
  deliveryTime: DeliveryTime;
  open: boolean;
  closeCallback: () => void;
}) => {
  const isSp = useJudgeSize();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [selectDeliveryTime, setSelectDeliveryTime] = useState<{
    deliveryTimeBegin: number;
    deliveryTimeEnd: number;
  } | null>(null);
  const deliveryTimeSlotOptions = dataOrder.deliveryTime.useDeliveryTimeOptionsOfOrder(
    deliveryTime,
    spotId
  );
  const changeDeliveryTime = dataOrder.deliveryTime.useChangeDeliveryTime();

  const changeCallback = async () => {
    if (selectDeliveryTime) {
      try {
        setProcessing(true);
        await changeDeliveryTime(orderId, selectDeliveryTime);
        setOpenSuccess(true);
      } catch (e) {
        console.error(e);
        setOpenError(true);
      }
      setProcessing(false);
    }
  };

  return (
    <Dialog open={open} onClose={closeCallback}>
      <DialogOk
        callbackOk={() => {
          setOpenError(false);
          closeCallback(); // エラー時はダイアログを閉じる
        }}
        open={openError}
        message="配達時間の変更ができませんでした。配達時間を変更して再度お試しください。"
      />
      <DialogOk
        callbackOk={() => window.location.reload()}
        open={openSuccess}
        message="配達時間を変更しました。"
      />
      <DialogTitle>配達時間の変更</DialogTitle>
      <DialogContent>
        <div className="m-4">
          <p className="sm:text-center sm:text-base text-sm">
            同一配達日内で配達時間の変更が行えます。
          </p>
          <p className="sm:text-center mt-2 sm:text-base text-sm">
            現在の配達時間: {isSp && <br />}
            <span className="font-bold ml-2 sm:ml-0">
              {deliveryTime.toStringDate() + ' ' + deliveryTime.toStringTime('〜')}
            </span>
          </p>

          {deliveryTimeSlotOptions.length === 0 ? (
            <p className="mt-8 text-center">選択できる配達時間がありません</p>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 overflow-y-auto mt-4 h-80">
              {deliveryTimeSlotOptions.map((slot, index) => (
                <div key={slot.getTimeOfBegin()}>
                  <Button
                    color="primary"
                    disableElevation
                    className="w-full"
                    variant={
                      selectDeliveryTime?.deliveryTimeBegin === slot.getTimeOfBegin()
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() =>
                      setSelectDeliveryTime({
                        deliveryTimeBegin: slot.getTimeOfBegin(),
                        deliveryTimeEnd: slot.getTimeOfEnd(),
                      })
                    }
                    disabled={
                      slot.available <= 0 || deliveryTime.getTimeOfBegin() === slot.getTimeOfBegin()
                    }
                  >
                    {slot.toStringTime(' ～ ')}
                    {' (' + slot.available + ')'}
                  </Button>
                </div>
              ))}
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={selectDeliveryTime === null || processing}
          onClick={() => changeCallback()}
        >
          変更
        </Button>
        <Button variant="outlined" onClick={closeCallback}>
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const OrderCancelBtn = ({ order }: { order: OrderItemState }) => {
  const cancelRequest = api.useCancelRequest(order.orderId);

  const [openDialogToCancel, setOpenDialogToCancel] = useState(false);
  const [openDialogCancelRequested, setOpenDialogCancelRequested] = useState(false);
  const [cancelRequestResult, setCancelRequestResult] =
    useState('キャンセル可能か確認しています。');

  return (
    <>
      <div
        className="w-full mt-16 py-3 rounded-full cursor-pointer 
                  text-center text-xl font-bold text-warning border-warning border-2"
        onClick={() => setOpenDialogToCancel(true)}
      >
        キャンセルする
      </div>

      {/* <p className="mt-2 text-warning">
        ※<span className="font-bold">⚪︎⚪︎時</span>
        までにキャンセルの場合、お支払いの金額は全額返金致します。
      </p> */}
      <DialogOkCancel
        open={openDialogToCancel}
        message="この注文をキャンセルしますか？"
        textOk="はい"
        textCancel="いいえ"
        callbackOk={() => {
          setOpenDialogCancelRequested(true);
          cancelRequest().then((result) => {
            console.log('cancelRequest result: ', result);
            // キャンセル可否の結果を格納する
            result
              ? setCancelRequestResult('注文はキャンセルされました。')
              : setCancelRequestResult('注文のキャンセルができませんでした。');
            setOpenDialogToCancel(false);
          });
        }}
        callbackCancel={() => {
          setOpenDialogToCancel(false);
        }}
      />
      <DialogOk
        open={openDialogCancelRequested}
        message={cancelRequestResult}
        callbackOk={() => {
          window.location.reload();
          setOpenDialogCancelRequested(false);
        }}
      />
    </>
  );
};

export function OrderDetails2() {
  const navigate = useNavigate();
  const { orderId } = useParams();
  if (!orderId) throw new Error('Routing Error.');
  const order = dataOrder.history.useOrderItem(orderId);
  const step = orderStateToStep(order?.state);
  const isSp = useJudgeSize();

  return (
    <>
      {step !== 4 && step >= 0 ? <Banner /> : <></>}

      <div className={`w-full py-4 ${isSp ? 'px-4' : 'px-32'}`}>
        {order ? (
          <>
            <OrderDetails3 order={order} />
          </>
        ) : (
          <Stack spacing={2} className="text-center pt-2">
            <div>ご注文を確認しています</div>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate('/order/history')}
                className="w-80"
              >
                注文履歴へ
              </Button>
            </div>
          </Stack>
        )}
      </div>
    </>
  );
}

export function OrderDetails3({ order }: { order: OrderItemState }) {
  // 配達中以前または配達時間の10分前までキャンセル可能
  const [isVisibleCancelBtn, setIsVisibleCancelBtn] = useState(true);

  useEffect(() => {
    setIsVisibleCancelBtn(
      (order.state === 'credited' ||
        order.state === 'accepted' ||
        order.state === 'assigned' ||
        order.state === 'waitingCustomerByStockOut') &&
        (order.deliveryTime?.getTimeOfBegin() ?? 0) - 10 * 60 * 1000 > Date.now()
    );
  }, [order.state]);

  return (
    <div>
      <OrderStateMessage order={order} />
      <div className="flex flex-col gap-1 mt-8">
        {order.state !== 'completed' ? <OrderContents order={order} /> : <></>}
        <OrderInfo order={order} />
      </div>

      {isVisibleCancelBtn && <OrderCancelBtn order={order} />}
    </div>
  );
}

const OrderContents = ({ order }: { order: OrderItemState }) => {
  const navigate = useNavigate();
  const isSp = useJudgeSize();
  const isPosted = (state: string) => state === 'posted';

  return (
    <>
      <div className="flex flex-col items-center">
        {isPosted(order.state) ? (
          <>
            <p className="text-warning">お受け取り期限</p>
            <p className="text-2xl font-bold text-warning">
              {order.deliveryTime?.getTimeOfBegin()
                ? moment(order.deliveryTime.getTimeOfBegin())
                    .add(
                      CONST.PICKUP_DEADLINE_MINUTES + CONST.NEXT_DELIVERY_SLOT_INTERVAL_MINUTES,
                      'minute'
                    )
                    .format('HH:mm')
                : ''}
            </p>
          </>
        ) : (
          <>
            <p>配達予定時刻</p>
            <p className="text-2xl font-bold">{order.deliveryTime?.toStringTime(' ～ ')}</p>
          </>
        )}
        <p className="mt-4">お届け先</p>
        <p className="text-2xl font-bold">{order.deliveryTo}</p>
      </div>

      {(order.state === 'credited' || order.state === 'accepted' || order.state === 'assigned') && (
        <DeliveryTimeChangeBtn order={order} />
      )}

      <div
        className="w-full mt-4 py-3 rounded-full cursor-pointer 
                  text-center text-xl font-bold text-primaryContrast bg-primary"
        onClick={() => {
          navigate('/order/qr/' + order.orderId);
        }}
      >
        受け取り用QRコードを表示する
      </div>
      <p className="ml-auto mt-2">
        <HelpOutlineIcon />
        商品のお受け取り方法は
        <span
          className="text-primary underline cursor-pointer"
          onClick={() => {
            navigate('/order/process/' + order.orderId);
          }}
        >
          こちら
        </span>
      </p>

      <div className="flex justify-center mt-2 text-sm">
        {isPosted(order.state) ? (
          <p className="text-warning">
            ※お受け取り期限を過ぎますと、商品のお受け取りをできない可能性がございますのでご注意ください。
          </p>
        ) : (
          <p>
            ※商品のお受け取り期限は
            <span className="font-bold">配達予定時刻の{CONST.PICKUP_DEADLINE_MINUTES}分後</span>
            までとなっております。予めご了承ください。
          </p>
        )}
      </div>
      <div className={`${isSp ? 'w-full' : 'w-6/12'} mx-auto mt-2`}>
        <ShippingMap order={order} />
      </div>
    </>
  );
};

const OrderInfo = ({ order }: { order: OrderItemState }) => {
  const navigate = useNavigate();

  return (
    <>
      <hr className="my-2" />
      <div>
        <p>お届け日：</p>
        <p className="font-bold">{order.deliveryTime?.toStringDate()}</p>
      </div>
      <div className="mt-2">
        <p>受付番号：</p>
        <p className="font-bold">{order.receiptNumber}</p>
      </div>
      <div className="mt-2">
        <p>ご注文番号：</p>
        <p className="font-bold">{order.orderId}</p>
      </div>
      <hr className="my-2" />
      <div className="font-bold">注文商品</div>
      {order.items.map((item) => (
        <div
          key={item.itemId}
          className="flex my-2 w-full items-center cursor-pointer rounded shadow shadow-gray-300"
        >
          <Grid container spacing={1} onClick={() => navigate('/shop/items/' + item.itemId)}>
            <Grid item xs={3}>
              <img
                alt="商品画像"
                src={dataShopItem.itemMaster.useShopItemMaster(item.itemId)?.image[0]}
                className="rounded-l object-cover h-20 w-full"
              />
            </Grid>
            <Grid item xs={9} className="p-2">
              <div className="pt-2 text-base line-clamp-2 lg:line-clamp-1">{item.name}</div>
              <div className="mt-1 flex">
                <div className="grow">
                  <Price price={item.price} />
                </div>
                <div>
                  <span className="text-lg font-bold">{item.count}</span>
                  <span className="text-xs"> 個</span>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      ))}
      <hr className="my-2" />
      <div className="flex items-center">
        商品合計（{order.totalCount}点）：
        <p className="ml-auto">
          <Price price={order.merchandisePrice} />
        </p>
      </div>
      <div className="flex items-center">
        送料：
        <p className="ml-auto">
          <Price price={order.deliveryFee} />
        </p>
      </div>
      {order.discount < 0 && (
        <div className="flex items-center">
          割引：
          <p className="ml-auto">
            <Price price={order.discount} />
          </p>
        </div>
      )}
      <div className="flex items-center">
        お支払い合計金額：
        <p className="ml-auto">
          <Price price={order.totalPrice} />
        </p>
      </div>
      <hr className="my-2" />
      <div>
        <p>お支払い情報</p>
        <p className="font-bold">{order.payMethod}</p>
      </div>
    </>
  );
};

const OrderStateMessage = ({ order }: { order: OrderItemState }) => {
  const step = orderStateToStep(order.state);

  const orderCancelCause = (cancelCause: string | undefined) => {
    switch (cancelCause) {
      case 'out of stock':
        return '在庫切れです。注文数を変更してください。';
      case 'fully booked':
        return '配送予約失敗です。別の時間を選択してください。';
      default:
        return '';
    }
  };

  return (
    <>
      {step >= 0 ? (
        <OrderStepper order={order} />
      ) : (
        <div>
          {order.state === 'cancelled' && (
            <>
              <div className="font-bold">ご注文はキャンセルされました。</div>
              <div className="ml-4 mt-2">
                キャンセル日時：{order.timeCancelled?.toLocaleString()}
              </div>
              <div className="ml-4 mt-2">{orderCancelCause(order.cancelCause)}</div>
            </>
          )}
          {order.state === 'failed' && <div className="font-bold">注文に失敗しました</div>}
          {order.state === 'payingTimeout' && (
            <div className="font-bold">お支払いに問題がありました。</div>
          )}
          {order.state === 'waitingCustomerByStockOut' && (
            <>
              <div className="font-bold">ご注文商品の中にご用意できない商品がございます。</div>
              <div className="ml-4 mt-2">メールにて詳細をご連絡しております。</div>
              <div className="ml-4 mt-1">内容をご確認ください。</div>
              <div className="mt-2 font-bold">ご用意できない商品</div>
              {order.stockOutItems?.map((item) => (
                <div
                  key={item.itemId}
                  className="flex my-2 w-full items-center rounded shadow shadow-gray-300"
                >
                  <div key={item.itemId} className="grid grid-cols-12 w-full">
                    <div className="col-start-1 col-end-4">
                      <img
                        alt="商品画像"
                        src={dataShopItem.itemMaster.useShopItemMaster(item.itemId)?.image[0]}
                        className="rounded-l object-cover h-20 w-full"
                      />
                    </div>
                    <div className="col-start-4 col-end-12 p-2">
                      <div className="pt-2 text-base line-clamp-2 lg:line-clamp-1">{item.name}</div>
                      <div className="mt-1 flex">
                        <div className="grow">
                          <Price price={item.price} />
                        </div>
                        <div>
                          <span className="text-lg font-bold">{item.count}</span>
                          <span className="text-xs"> 個</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      )}
      {order.state === 'completed' ? (
        <div className="mt-8 break-words">
          お受け取りが完了しました。
          <br />
          この度はソラカラをご利用いただき、ありがとうございました！
          <br />
          今後のサービス改善の参考にさせていただくため、皆さまのご意見をお聞かせください。以下のボタンからアンケートにご回答ください。
          <a
            className="w-9/10 mt-2 py-3 rounded-full cursor-pointer block
              text-center text-xl font-bold text-white bg-primary"
            href="https://forms.office.com/r/jLnuZye3Px"
            target="_blank"
            rel="noopener noreferrer"
          >
            アンケートはこちら
          </a>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const OrderStepper = ({ order }: { order: OrderItemState }) => {
  const step = orderStateToStep(order.state);

  // ステップの間の線のスタイル
  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.primary.main,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.primary.main,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  // ステップのアイコンのスタイル
  const StepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(() => ({
    display: 'flex',
    height: 22,
    alignItems: 'center',
    justifyContent: 'center',
  }));

  function StepIcon(props: StepIconProps) {
    const { completed, active, className } = props;

    return (
      <StepIconRoot ownerState={{ active }} className={className}>
        {active ? (
          <Drone fill="currentColor" className="h-10" />
        ) : completed ? (
          /* HACK: 無理やりステッパーの線を繋げているので、良い方法があれば修正 */
          <div className="border-t-[3px] rounded-[1px] w-12 top-3 absolute border-primary" />
        ) : (
          <div className="border-t-[3px] rounded-[1px] w-12 top-3 absolute border-[#bdbdbd]" />
        )}
      </StepIconRoot>
    );
  }

  return (
    <>
      <p className="text-center text-primary text-xl font-bold">
        {step === 0 && 'お店がご注文を確認しています'}
        {step === 1 && 'お店が商品の準備をしています'}
        {step === 2 && '空中配送ロボットが商品を配達中です'}
        {step === 3 && '商品のお受け取りが可能です'}
        {step === 4 && '商品受け取り済'}
      </p>
      <Stepper
        activeStep={step}
        orientation="horizontal"
        alternativeLabel
        connector={<QontoConnector />}
      >
        <Step>
          <StepLabel StepIconComponent={StepIcon} />
        </Step>

        <Step>
          <StepLabel StepIconComponent={StepIcon} />
        </Step>

        <Step>
          <StepLabel StepIconComponent={StepIcon} />
        </Step>

        <Step>
          <StepLabel StepIconComponent={StepIcon} />
        </Step>
      </Stepper>
    </>
  );
};

// switch文の条件が複数あるときは、eslintのcomplexityを無効にする
// eslint-disable-next-line complexity
const orderStateToStep = (state: string | undefined) => {
  switch (state) {
    case 'paying':
      return 0;
    case 'credited':
      return 0;
    case 'assigned':
      return 1;
    case 'accepted':
      return 1;
    case 'onDelivery':
      return 2;
    case 'arrived':
      return 2;
    case 'posted':
      return 3;
    case 'completed':
      return 4;
    case 'payingTimeout':
      return -1;
    case 'failed':
      return -1;
    case 'cancelled':
      return -1;
    case 'waitingCustomerByStockOut':
      return -1;
    default:
      return -999;
  }
};
