import { UserForm } from './UserForm';
import { PasswordForm } from './PasswordForm';
import { Account } from '@common/components/Account';
import { PhoneForm } from './PhoneForm';

export const UserInfo = () => {
  return (
    <>
      <UserForm title="ユーザー情報" btnText="更新" />
      <PhoneForm />
      <PasswordForm title="パスワード変更" btnText="変更" />
      <Account />
    </>
  );
};
