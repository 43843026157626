import { DialogOk } from '@common/components/Dialog';
import { Backdrop, Button, CircularProgress, Paper, TextField } from '@mui/material';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  type FormType = {
    mailAddress: string;
  };
  const { handleSubmit, control } = useForm<FormType>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      mailAddress: '',
    },
  });

  const onSubmit: SubmitHandler<FormType> = async (data) => {
    try {
      setProcessing(true);
      await Auth.forgotPassword(data.mailAddress);
      setProcessing(false);
      navigate(`/resetPassword/${data.mailAddress}`);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: Error | any) {
      setProcessing(false);
      if (error.code) {
        console.error(error);
        if (error.code === 'UserNotFoundException') {
          setMessage('登録されていないメールアドレスです');
        } else if (error.code === 'LimitExceededException') {
          setMessage('試行の回数の上限に達しました。時間をおいて再度実行してください。');
        } else {
          setMessage('想定外のエラーです');
        }
      }
      setOpen(true);
    }
    return;
  };

  return (
    <div>
      <Backdrop open={processing} sx={{ zIndex: 10000 }}>
        <CircularProgress />
      </Backdrop>
      <DialogOk
        open={open}
        callbackOk={() => setOpen(false)}
        title="エラー"
        message={message}
      ></DialogOk>

      <div className="w-full mt-4 flex justify-center items-center">
        <Paper className="w-[22rem] p-8" elevation={6}>
          <p className="font-semibold text-xl">パスワードリセット</p>
          <p className="mt-2 text-sm">パスワードのリセットメールを送信します。</p>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col items-center gap-4 mx-auto mt-1"
          >
            <Controller
              name="mailAddress"
              control={control}
              rules={{ required: { value: true, message: '入力必須項目です' } }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="メールアドレス"
                  variant="standard"
                  size="medium"
                  className="w-full"
                  error={Boolean(error?.message)}
                  helperText={error?.message}
                  {...field}
                />
              )}
            />
            <div className="mt-2">
              <Button variant="contained" type="submit">
                送信
              </Button>
            </div>
          </form>
        </Paper>
      </div>
    </div>
  );
};
