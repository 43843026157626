import { userActions } from '@common/data/User';
import { Navigate } from 'react-router-dom';
import { DataWantedRoute } from '@common/data/WantedRoute';
import { PhoneForm } from './PhoneForm';

export const PhoneInitialRegistration = () => {
  // MEMO: 初期登録用のコンポーネントのため、すでにユーザー情報が登録されていればリダイレクト
  // TODO: serviceWorker更新のリロード時に、ユーザー情報が登録されていても
  // こちらのコンポーネントが表示されてしまう件の原因がわかり次第、別対応を取る
  const user = userActions.useGetUser();
  const wantedRoute = DataWantedRoute.useWantedRouteValue();
  if (user.phoneNumberVerified) {
    return <Navigate to={wantedRoute} replace />;
  }

  return <PhoneForm navigateUrl={wantedRoute} />;
};
