import { dataShop } from '@common/data/Shop';
import { dataShopItemMaster, ShopItemMasterStock } from '@common/data/ShopItemMaster';
import { dataShopItemStock } from '@common/data/ShopItemStock';
import { userActions } from '@common/data/User';
import { Badge } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ShopSearchForm } from './ShopSearchForm';

export const ShopSearchResult = () => {
  const filteredItems = dataShopItemMaster.useShopItemMasterFiltered();

  return (
    <div className="mt-2 pb-1  w-full">
      <ShopSearchForm defaultExpand={true} />

      {filteredItems.length === 0 ? (
        <div className="mt-2 text-center">検索結果が見つかりません</div>
      ) : (
        <FilteredItems filteredItems={filteredItems} />
      )}
    </div>
  );
};

const FilteredItems = ({
  filteredItems,
}: {
  filteredItems: (ShopItemMasterStock | undefined)[];
}) => {
  const navigate = useNavigate();

  const cognitoUserInfo = userActions.useCognitoUserInfo();
  const [itemMaster] = dataShopItemMaster.useShopItemsMasterState();
  const selectedShop = dataShop.useShopSelected();
  const [stockMaster] = dataShopItemStock.useShopItemsStockAllState(selectedShop.shopId);
  const updateItemMaster = dataShopItemMaster.useShopItemMasterUpdate();
  const updateItemStock = dataShopItemStock.useShopItemStockUpdate();

  return (
    <div className="grid grid-cols-3 gap-y-4 mx-2">
      {filteredItems.map((item) =>
        item === undefined ? (
          <></>
        ) : (
          <div
            key={item.itemId}
            className="shrink-0 flex flex-col items-center rounded-md shadow cursor-pointer"
            style={{ width: '29vw' }}
            onClick={() => {
              updateItemMaster(item.itemId, itemMaster);
              updateItemStock(item.itemId, stockMaster);
              navigate('/shop/items/' + item.itemId);
            }}
          >
            <div className="w-full h-24 relative">
              <img
                alt="商品画像"
                src={item.image[0]}
                className="w-full h-full object-contain rounded-t-md"
                loading="lazy"
              />
              {item.stockCount <= 0 && (
                <div className="absolute bottom-0 left-0 right-0 px-2 text-white bg-black/75">
                  <div className="font-bold">売り切れ</div>
                </div>
              )}
              {item.stockCount > 0 && item.stockCount <= (item.limitedStock ?? 1) && (
                <div className="absolute bottom-0 left-0 right-0 px-2 text-white bg-warning/50">
                  <div className="font-bold">残り僅か</div>
                </div>
              )}
            </div>
            <div className="p-2 w-full grow flex flex-col">
              <div className="text-xs font-bold text-primary-light line-clamp-1">
                {item.makerName}
              </div>
              <div className="grow text-base line-clamp-2 lg:line-clamp-1">{item.name}</div>
              {cognitoUserInfo.groups.includes('Developer') && (
                <Badge badgeContent={item.stockCount} color="secondary" max={100000}></Badge>
              )}
              <div className="mt-1 text-right">
                <span className="text-md font-bold">{item.price.toLocaleString()}</span>
                <span className="text-xs"> 円（税込）</span>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};
