import { Paper } from '@mui/material';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { theme } from '../app/theme';

export const Banner = () => {
  return (
    <Paper
      sx={{
        padding: 2,
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <QuestionAnswerIcon fontSize="large" />
      <a
        className="break-words"
        href="https://forms.office.com/r/jLnuZye3Px"
        target="_blank"
        rel="noopener noreferrer"
      >
        商品をお待ちの間、<span className="underline">こちら</span>
        から簡単なアンケートにご協力ください。
        <br />
        （一部、商品到着後に関する質問はスキップしていただいて構いません）
      </a>
    </Paper>
  );
};
