import { agreementActions } from '@common/data/Agreement';
import { dataCoupons } from '@common/data/OrderCoupons';
import { dataDeliveryTime } from '@common/data/OrderDeliveryTime';
import { dataDeliveryTo } from '@common/data/OrderDeliveryTo';
import { dataShop } from '@common/data/Shop';
import { dataShopItem } from '@common/data/ShopItem';
import { userActions } from '@common/data/User';

// 各種atom,selectorの初期化
export const useDataInitialize = () => {
  // MEMO: loadableで取得しないとpromiseをthrowする(suspendされ、順次実行される)
  userActions.useGetUserLoadable();
  agreementActions.useGetAgreementsLoadable();
  dataCoupons.useCouponsLoadable();
  dataDeliveryTime.useDeliveryTimeOptionsLoadable();
  dataShopItem.category.useCategoriesLoadable();
  dataShopItem.itemMaster.useAllShopItemsLoadable();
  dataShopItem.maker.useAllShopItemMakerLoadable();
  const selectedShop = dataShop.useShopSelected();
  dataShopItem.stock.useShopItemStockLoadable(selectedShop?.shopId);
  dataShopItem.offer.useOfferItemsLoadable();
  dataDeliveryTo.useDeliveryToLoadable();
};
