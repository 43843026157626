import { useNavigate } from 'react-router-dom';

// MUI
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import landingImg from '@common/assets/landing.png';

export function LPIzukogen() {
  const navigate = useNavigate();

  return (
    <Box sx={{ width: '100%', p: 1, pb: 28, mb: 12 }}>
      {
        <Stack spacing={2} className="text-center pt-2">
          <img src={landingImg} alt=""></img>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/spot/0')}
              className="w-80"
            >
              伊豆高原店へ
            </Button>
          </div>
        </Stack>
      }
    </Box>
  );
}
