import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

// MUI
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export function UsageFlow() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box sx={{ width: '100%', p: 1, pb: 28, mb: 12, position: 'relative', minHeight: '100vh' }}>
        <Typography variant="h5" component="h4" sx={{ mb: 3, textAlign: 'left' }}>
          ご利用の流れ
        </Typography>
        <Card sx={{ mb: 2 }}>
          <CardContent>
            <Typography variant="h6" component="h4" align="left">
              ①会員登録
            </Typography>
            <Typography align="left">
              メールアドレス、携帯電話番号、パスワードを登録し、メールで届いた認証番号を入力してください。
              <Typography component="span" sx={{ display: 'block', fontSize: '0.75rem', mt: 1 }}>
                ※会員登録後は、携帯電話番号の認証と氏名・住所の入力が必要です。
              </Typography>
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ mb: 2 }}>
          <CardContent>
            <Typography variant="h6" component="h4" align="left">
              ②商品と受取時間の選択
            </Typography>
            <Typography align="left">
              お好みの商品をカートに追加し、ご希望のお届け時間をお選びください。10分単位で指定できます。
              <Typography component="span" sx={{ display: 'block', fontSize: '0.75rem', mt: 1 }}>
                ※お受取り期限があります。表示される受取期限をご確認の上、ご希望の時間をご選択ください。
              </Typography>
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ mb: 2 }}>
          <CardContent>
            <Typography variant="h6" component="h4" align="left">
              ③お支払い
            </Typography>
            <Typography align="left">
              お支払いはクレジットカード決済・PayPay・d払い・ソフトバンクまとめて支払いがご利用いただけます。
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ mb: 2 }}>
          <CardContent>
            <Typography variant="h6" component="h4" align="left">
              ④受取場所まで移動
            </Typography>
            <Typography align="left">
              空中配送ロボットが受取ボックスに到着した際に、メールで連絡します。受取ボックスまでご移動をお願いします。
              <Typography component="span" sx={{ display: 'block', fontSize: '0.75rem', mt: 1 }}>
                ※状況により、空中配送ロボットによる配達ではない場合がございます。あらかじめご了承ください。
              </Typography>
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ mb: 2 }}>
          <CardContent>
            <Typography variant="h6" component="h4" align="left">
              ⑤商品の受取り
            </Typography>
            <Typography align="left">
              アプリに表示されるQRコードを受取ボックスにかざし、商品をお受け取りください。
            </Typography>
          </CardContent>
        </Card>
        <div
          className="w-full py-3 my-1 mb-3 rounded-full cursor-pointer text-center text-2xl font-bold text-primary border-primary border-2"
          onClick={() => {
            navigate(-1); // 1つ前のページに戻る
          }}
        >
          戻る
        </div>
      </Box>
    </>
  );
}
