import { InputAdornment, TextField } from '@mui/material';
import { useState } from 'react';
import { dataShopItemMaster } from '@common/data/ShopItemMaster';
import { useNavigate } from 'react-router-dom';
import { useJudgeSize } from '@common/utils/JudgeSize';
import SearchIcon from '@mui/icons-material/Search';

export const ShopSearchForm = ({ defaultExpand = false }: { defaultExpand?: boolean }) => {
  const navigate = useNavigate();
  const [filterCondition, setFilterCondition] = dataShopItemMaster.useFilterCondition();
  const [keyword, setKeyword] = useState(filterCondition.keyword);
  const isSp = useJudgeSize();
  const searchButtonCallback = () => {
    setFilterCondition({ keyword: keyword });
    navigate('/shop/search');
  };

  return (
    <div className={`${isSp ? 'w-full' : 'w-1/2'} mx-auto my-2 p-2`}>
      <div className="px-2 flex gap-2">
        {/* <input
          type="text"
          className="flex-grow bg-yellow-100 rounded-full p-2"
          placeholder="キーワード検索"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        /> */}
        <TextField
          className="flex-grow"
          placeholder="商品を検索"
          variant="standard"
          sx={{
            background: 'rgba(227,230,230,0.3)',
            borderRadius: '50px',
            paddingY: '0.5rem',
            paddingX: '1rem',
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          onKeyDown={(e) => {
            console.log(e);
            if (e.code === 'Enter') {
              // エンターキー押下時の処理
              searchButtonCallback();
            }
          }}
        />
      </div>
    </div>
  );
};

// TODO: 検索がキーワードのみの場合は削除
// export const ShopSearchForm2 = ({ defaultExpand = false }: { defaultExpand?: boolean }) => {
//   const navigate = useNavigate();
//   const [filterCondition, setFilterCondition] = dataShopItemMaster.useFilterCondition();
//   const [category, setCategory] = useState(filterCondition.categoryId);
//   const [keyword, setKeyword] = useState(filterCondition.keyword);
//   const categories = dataShopItemCategory.useCategories();
//   const isSp = useJudgeSize();

//   const [isExpand, setIsExpand] = useState<boolean>(defaultExpand);

//   const searchButtonCallback = () => {
//     setFilterCondition({ categoryId: category, keyword: keyword });
//     navigate('/shop/search');
//   };

//   return (
//     <div className={`${isSp ? 'w-full' : 'w-1/2'} mx-auto my-2 p-2`}>
//       <Accordion className="w-full border my-2" expanded={isExpand}>
//         <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => setIsExpand(!isExpand)}>
//           <SearchIcon color="primary" />
//           <p className="my-auto ml-1">絞り込み検索</p>
//         </AccordionSummary>
//         <AccordionDetails>
//           <div className="px-2 flex gap-2">
//             <TextField
//               label="キーワード検索"
//               className="flex-grow"
//               value={keyword}
//               onChange={(e) => setKeyword(e.target.value)}
//             />
//             <Button variant="contained" size="large" onClick={searchButtonCallback}>
//               検索
//             </Button>
//           </div>
//           <div className="mt-4 flex gap-2 px-2">
//             <FormControl className="w-full">
//               <InputLabel id="category-label">カテゴリー</InputLabel>
//               <Select
//                 label="カテゴリー"
//                 labelId="category-label"
//                 value={category}
//                 onChange={(e) => setCategory(e.target.value)}
//               >
//                 <MenuItem value="all">全て</MenuItem>
//                 {categories.map((category) => {
//                   return (
//                     <MenuItem key={category.categoryId} value={category.categoryId}>
//                       {category.categoryName}
//                     </MenuItem>
//                   );
//                 })}
//               </Select>
//             </FormControl>
//           </div>
//         </AccordionDetails>
//       </Accordion>
//     </div>
//   );
// };
