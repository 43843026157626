import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// MUI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
// MUI Icon
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';

// import { api } from '@common/data/Api';
import { CONST } from '@common/global';
import { dataOrder } from '@common/data/Order';
// TODO : 要除去。demo用に一時的にstate更新APIを設置してある。
import { dataShopItemStock } from '@common/data/ShopItemStock';
import { dataShopItemMaster } from '@common/data/ShopItemMaster';
import { isToday } from '@common/utils/etc';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { dataDeliveryTo } from '@common/data/OrderDeliveryTo';
import { useJudgeSize } from '@common/utils/JudgeSize';
import { OrderStepper } from './OrderStepper';

export function SelectDeliveryTime() {
  const [select, setSelect] = useState(
    dataOrder.deliveryTime.useDeliveryFirstAvailableTimeOptionIndex()
  );
  const deliveryTimeSlotOptions = dataOrder.deliveryTime.useDeliveryTimeOptionsOfSelectedDate();
  const dateOptions = dataOrder.deliveryTime.useDeliveryDateTodayOption();
  const cartDate = dataOrder.cart.useCartDate();
  const setCartDate = dataOrder.cart.useSetCartDate();
  const setDeliveryTime = dataOrder.deliveryTime.useSetDeliveryTime();
  const navigate = useNavigate();
  const refreshStock = dataShopItemStock.useShopItemStockRefresher();
  const refreshCap = dataOrder.deliveryTime.useDeliveryTimeOptionRefresher();
  const refreshItems = dataShopItemMaster.useShopItemRefresher();

  // 選択しているお届け時間が変わる際に状態を更新
  useEffect(() => {
    setDeliveryTime(deliveryTimeSlotOptions[select]);
  }, [select, deliveryTimeSlotOptions, setDeliveryTime]);

  const getDayString = (date: Date) => {
    const day = date.getDay();
    return ['日', '月', '火', '水', '木', '金', '土'][day];
  };

  const selectCallback = () => {
    setDeliveryTime(deliveryTimeSlotOptions[select]);
    refreshStock();
    refreshCap();
    refreshItems();
    navigate('/order/confirm');
  };

  return (
    <Box sx={{ width: '100%', p: 1, pb: 8 }}>
      <OrderStepper activeStep={2} />
      <div className="mt-2">
        <div className="text-2xl">
          <AccessTimeRoundedIcon className="mb-1" /> お届け時間の選択
        </div>
        {select < 0 && (
          <div className="ml-8 text-warning-500">
            選択されたお届け先は、お届け可能な時間がないため、 注文を承ることができません。
            <br />
            別のお届け先を選択するか、注文再開までお待ちください。
          </div>
        )}
        {dateOptions.length !== 0 && (
          <div className="m-4">
            <div className="h-18 flex flex-shrink-0 flex-nowrap gap-2 bg-orange-500 p-2 overflow-x-auto">
              {dateOptions.map((d) => (
                <button
                  key={d.getTime()}
                  onClick={(e) => {
                    setCartDate(new Date(Number(e.currentTarget.value)));
                  }}
                  value={d.getTime()}
                  className={`${
                    cartDate.getTime() === d.getTime() ? 'bg-primary' : 'bg-orange-500'
                  } px-2 text-primaryContrast whitespace-nowrap rounded-md cursor-pointer border-2 border-orange-400`}
                >
                  <span className="text-xs">{getDayString(d)}</span>
                  <br />
                  <span className="text-sm font-bold">
                    {!isToday(d) && `${d.getMonth() + 1}月${d.getDate()}日`}
                    {isToday(d) && '今日'}
                  </span>
                </button>
              ))}
            </div>
            <div className="mt-2 grid gap-2 md:grid-cols-2 grid-cols-1 md:h-[45vh] h-[30vh] auto-rows-min overflow-y-auto pb-8">
              {deliveryTimeSlotOptions.map((slot, index) => (
                <Button
                  key={index}
                  color="primary"
                  disableElevation
                  className="w-full h-fit"
                  variant={select === index ? 'contained' : 'outlined'}
                  onClick={() => setSelect(index)}
                  disabled={slot.available <= 0}
                >
                  {slot.toStringTime(' ～ ')}
                  {' (' + slot.available + ')'}
                </Button>
              ))}
            </div>
          </div>
        )}
      </div>

      <PurchasePanel currentIndex={select} selectCallback={selectCallback} />
    </Box>
  );
}

type PurchasePanelProps = {
  readonly currentIndex: number;
  selectCallback: () => void;
};

const PurchasePanel = (purchasePanelProps: PurchasePanelProps) => {
  const [isOpen, setIsOpen] = useState(false);

  // pb:7 は MyBottomNavigation に隠れる高さ
  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        p: 1,
        pb: 8,
      }}
    >
      <ConfirmDialog
        isOpen={isOpen}
        selectedIndex={purchasePanelProps.currentIndex}
        selectCallback={purchasePanelProps.selectCallback}
        closeCallback={() => {
          setIsOpen(false);
        }}
      />
      <Stack direction="column" spacing={2}>
        {purchasePanelProps.currentIndex >= 0 && (
          <p>
            ※商品のお受け取り期限は
            <span className="font-bold">配達予定時刻の{CONST.PICKUP_DEADLINE_MINUTES}分後</span>
            までとなっております
          </p>
        )}
        {/* <Box
          className="w-full py-3 rounded-full cursor-pointer 
                  text-center text-2xl font-bold text-primary bg-primaryContrast border-primary border"
          onClick={() => {
            navigate('/order/select_box');
          }}
        >
          お届け先の確認に戻る
        </Box> */}
        {purchasePanelProps.currentIndex < 0 ? (
          <Box
            className="w-full py-3 rounded-full cursor-pointer 
          text-center text-2xl font-bold text-warningContrast bg-warning"
          >
            別のお届け先を選択するか
            <br />
            注文再開までお待ちください
          </Box>
        ) : (
          <>
            <Box
              className="w-full py-3 rounded-full cursor-pointer 
                  text-center text-2xl font-bold text-primaryContrast bg-primary"
              onClick={() => {
                setIsOpen(true);
              }}
            >
              お届け日時を決定する
            </Box>
          </>
        )}
      </Stack>
    </Paper>
  );
};

const ConfirmDialog = ({
  isOpen,
  selectedIndex,
  selectCallback,
  closeCallback,
}: {
  isOpen: boolean;
  selectedIndex: number;
  selectCallback: () => void;
  closeCallback: () => void;
}) => {
  const selectedSpot = dataDeliveryTo.useSpotSelected();
  const deliveryTimeSlotOptions = dataOrder.deliveryTime.useDeliveryTimeOptionsOfSelectedDate();
  const isSp = useJudgeSize();

  if (selectedIndex < 0) return <></>;

  return (
    <Dialog open={isOpen}>
      <DialogTitle>確認</DialogTitle>
      <DialogContent>
        <div>
          <p className="text-sm">受取BOX</p>
          <p className="font-bold">{selectedSpot.spotName}</p>
        </div>
        <div className="mt-2">
          <p className="text-sm">お届け日時</p>
          <p className="font-bold">
            {deliveryTimeSlotOptions[selectedIndex].toStringDate() +
              ' ' +
              deliveryTimeSlotOptions[selectedIndex].toStringTime('〜')}
          </p>
        </div>
        <div className="mt-2">
          <p className="text-sm">お受け取り期限</p>
          <p className="font-bold">
            {deliveryTimeSlotOptions[selectedIndex].toStringDate() +
              ' ' +
              deliveryTimeSlotOptions[selectedIndex].toStringLimitTime()}
          </p>
        </div>
        <p className="mt-2 text-sm">
          ※お受け取り期限を過ぎると商品を回収する場合がございます。{!isSp && <br />}
          お時間にご注意をお願い致します。
        </p>
      </DialogContent>
      <DialogActions>
        <div
          className="bg-primaryContrast text-primary border border-primary cursor-pointer rounded-md py-2 px-4 font-bold"
          onClick={closeCallback}
        >
          キャンセル
        </div>
        <div
          className="bg-primary text-primaryContrast cursor-pointer rounded-md py-2 px-4 font-bold"
          onClick={selectCallback}
        >
          OK
        </div>
      </DialogActions>
    </Dialog>
  );
};
