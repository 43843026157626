import { DialogOk } from '@common/components/Dialog';
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Auth } from 'aws-amplify';
import { parseNumberString } from '@common/utils/Formatter';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { userActions } from '@common/data/User';

export const ConfirmDialog = ({
  isOpen,
  username,
  password,
}: {
  isOpen: boolean;
  username: string;
  password: string;
}) => {
  const setLogin = userActions.useSetLogin();

  const [processing, setProcessing] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSignUpSuccess, setOpenSignUpSuccess] = useState(false);
  const [openReSendSuccess, setOpenReSendSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const codeLength = 6; // 認証コードの桁数
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [code, setCode] = useState<string[]>(Array(codeLength).fill(''));

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number): void => {
    const value = parseNumberString(e.target.value, 'integer');

    setCode((prevCode) => {
      const newCode = [...prevCode];
      newCode[index] = value;
      return newCode;
    });

    if (value !== '' && index < codeLength - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number): void => {
    if (e.key === 'Backspace' && code[index] === '') {
      setCode((prevCode) => {
        const newCode = [...prevCode];
        newCode[index - 1] = '';
        return newCode;
      });

      if (index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>, index: number): void => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text/plain');
    const pastedCode = parseNumberString(pastedData.slice(0, codeLength - index), 'integer');

    setCode((prevCode) => {
      const newCode = [...prevCode];
      newCode.splice(index, pastedCode.length, ...pastedCode.split(''));
      return newCode;
    });

    if (index + pastedCode.length < codeLength) {
      inputRefs.current[index + pastedCode.length]?.focus();
    } else {
      inputRefs.current[codeLength - 1]?.focus();
    }
  };

  const submit = async () => {
    setProcessing(true);
    try {
      await Auth.confirmSignUp(username, code.join(''));
      await Auth.signIn(username, password);
      setLogin();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: Error | any) {
      setProcessing(false);
      // サインアップ失敗時の処理
      if (error.code) {
        console.error(error);
        if (error.code === 'CodeMismatchException') {
          setMessage(
            '無効なコードが入力されました、確認コードを再送し、再度入力を行なってください'
          );
        } else if (error.code === 'LimitExceededException') {
          setMessage(
            '確認コードが複数回誤って入力されました、確認コードを再送し、再度入力を行なってください'
          );
        } else if (error.code === 'ExpiredCodeException') {
          setMessage('確認コードが期限切れです、確認コードを再送し、再度入力を行なってください');
        } else if (error.code === 'NotAuthorizedException') {
          setMessage('既に確認済みです、ログインを行なってください');
        } else {
          setMessage('想定外のエラーです');
          console.error(error);
        }
        setOpen(true);
      }
      return;
    }
    setProcessing(false);
    setOpenSignUpSuccess(true);
  };

  const reSendConfirmCode = async () => {
    setProcessing(true);
    try {
      await Auth.resendSignUp(username);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: Error | any) {
      setProcessing(false);
      // サインアップ失敗時の処理
      if (error.code) {
        console.error(error);
        if (error.code === 'CodeDeliveryFailureException') {
          setMessage('確認コードの再送信に失敗しました');
        } else {
          setMessage('想定外のエラーです');
          console.error(error);
        }
        setOpen(true);
      }
      return;
    }
    setProcessing(false);
    setOpenReSendSuccess(true);
  };

  return (
    <Dialog open={isOpen}>
      <Backdrop open={processing} sx={{ zIndex: 1000 }}>
        <CircularProgress />
      </Backdrop>
      <DialogOk
        open={open}
        callbackOk={() => setOpen(false)}
        title="エラー"
        message={message}
      ></DialogOk>
      <DialogOk
        open={openSignUpSuccess}
        callbackOk={() => navigate('/top', { replace: true })}
        title="ユーザ登録が完了しました"
        message="規約に同意の上、情報を入力し、お買い物をお楽しみください"
      ></DialogOk>
      <DialogOk
        open={openReSendSuccess}
        callbackOk={() => setOpenReSendSuccess(false)}
        title="確認コードを再送信しました"
        message="メールを確認し、確認コードを入力してください"
      ></DialogOk>

      <DialogTitle>メールアドレス認証</DialogTitle>
      <DialogContent>
        <p className="mt-2 text-sm">
          お使いのメールアドレスに6桁の認証コードをお送り致しました。
          <br />
          コードを以下に入力すると、 会員登録が完了致します。
        </p>
        <div className="mt-4 flex justify-center gap-2">
          {code.map((char, index) => (
            <input
              key={index}
              type="text"
              inputMode="numeric"
              maxLength={1}
              value={char}
              onChange={(e) => handleInputChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onPaste={(e) => handlePaste(e, index)}
              ref={(ref) => (inputRefs.current[index] = ref)}
              className="w-10 h-10 border border-black rounded-md cursor-pointer text-center"
            />
          ))}
        </div>

        <div className="mt-4 flex justify-center">
          <Button variant="contained" onClick={submit}>
            会員登録を完了する
          </Button>
        </div>
        <p
          className="text-sm mt-4 cursor-pointer underline text-primary"
          onClick={reSendConfirmCode}
        >
          認証コードを再送する
        </p>
      </DialogContent>
    </Dialog>
  );
};
